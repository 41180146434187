import React from 'react';
import Echarts from 'echarts-for-react';
import purple from '@material-ui/core/colors/purple';
import EmptyState from './UI/EmptyState';
import { EDUCATION_BY_VALUE } from 'utils/constants';

function getOption(jurors) {
  let keys = [],
    data = [];
  for (let [key, value] of Object.entries(jurors).reverse()) {
    let label = EDUCATION_BY_VALUE[key];
    if (label.length > 12) {
      label = label.substring(0, 11).trim() + '...';
    }
    keys.push({
      value: label,
      textStyle: {
        fontSize: 10,
      },
    });
    data.push(value);
  }

  return {
    xAxis: {
      type: 'category',
      data: keys,
    },
    yAxis: {
      type: 'value',
      interval: 1,
      show: true,
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b} : {c}',
    },
    series: [
      {
        data,
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(220, 220, 220, 0.8)',
        },
        itemStyle: {
          color: purple[500],
        },
      },
    ],
  };
}

const EducationChart = ({ jurors }) => {
  if (!Object.keys(jurors).length) {
    return <EmptyState>Please fill out your juror cards first.</EmptyState>;
  }
  return <Echarts option={getOption(jurors)} />;
};

export default EducationChart;
