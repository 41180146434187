import React from 'react';
import Echarts from 'echarts-for-react';
import { useTheme } from '@material-ui/core/styles';
import { sentenceCase } from 'change-case';
import { EmptyState } from 'components';

function useOption(questions) {
  const theme = useTheme();
  const data = Object.entries(questions).map(([party, questionsArr]) => {
    return {
      name: sentenceCase(party),
      value: (questionsArr as unknown[]).length,
      itemStyle: {
        // @ts-ignore
        color: theme.palette[party].main,
      },
    };
  });

  return {
    display: Boolean(data.length),
    option: {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)',
      },
      series: [
        {
          name: 'Ethnicities',
          type: 'pie',
          radius: '90%',
          center: ['50%', '50%'],
          data,
        },
      ],
    },
  };
}

const QuestionsChart = ({ questions }) => {
  const { display, option } = useOption(questions);
  if (!display) return <EmptyState>No questions found.</EmptyState>;
  return <Echarts option={option} />;
};

export default React.memo(QuestionsChart);
