import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, ButtonBase } from '@material-ui/core';
import {
  Chat as FreeTextIcon,
  List as MultiIcon,
  LinearScale as ScaleIcon,
} from '@material-ui/icons';
import { AskingPartyBadger } from 'components';

const useStyles = makeStyles((theme) => ({
  responseHeading: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.grey[50],
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
    textAlign: 'left',
  },
  responseBody: {
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    '&:last-of-type': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  grow: {
    flexGrow: 1,
  },
  bold: {
    fontWeight: 700,
  },
  scaleResponseContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateRows: '1fr auto',
    gridGap: theme.spacing(1),
  },
  multiResponseContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '30px auto',
    gridGap: theme.spacing(1),
  },
  prosecution: {
    // @ts-ignore
    borderLeft: `3px solid ${theme.palette.prosecution.main}`,
  },
  defense: {
    // @ts-ignore
    borderLeft: `3px solid ${theme.palette.defense.main}`,
  },
  judge: {
    // @ts-ignore
    borderLeft: `3px solid ${theme.palette.judge.main}`,
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

interface QuestionResponseDetailProps {
  askingParty?: 'prosecution' | 'defense' | 'judge';
  question?: string;
  type?: string;
  options?: any;
  response?: any;
  responseId?: string;
}

const QuestionResponseDetail: React.FC<QuestionResponseDetailProps> = ({
  askingParty,
  question,
  type,
  options,
  response,
  responseId,
}) => {
  const classes = useStyles();

  let multiResponses = [];

  if (type === 'multi') {
    for (let [choice, value] of Object.entries(response)) {
      if (!value) continue;
      if (choice === 'notes') continue;
      multiResponses.push(options.choices[Number(choice)]);
    }
  }

  return (
    <>
      <div className={cx(classes.responseHeading, classes[askingParty])}>
        <AskingPartyBadger size="small" party={askingParty} />
        {type === 'multi' && <MultiIcon />}
        {type === 'freetext' && <FreeTextIcon />}
        {type === 'scale' && <ScaleIcon />}
        <Typography className={classes.grow} variant="body1">
          {question}
        </Typography>
      </div>
      <div className={classes.responseBody}>
        {type === 'freetext' && (
          <Typography
            variant="body2"
            color={response.text.trim() ? 'textPrimary' : 'textSecondary'}
          >
            {response.text.trim() || '(Empty response)'}
          </Typography>
        )}
        {type === 'scale' && (
          <div className={classes.scaleResponseContainer}>
            <Typography className={classes.bold} variant="body2">
              Value
            </Typography>
            <Typography variant="body2">
              {response.value} / {options.max}
            </Typography>
            <Typography className={classes.bold} variant="body2">
              Notes
            </Typography>
            <Typography
              variant="body2"
              color={response.notes?.trim() ? 'textPrimary' : 'textSecondary'}
            >
              {response.notes?.trim() || '(Empty notes)'}
            </Typography>
          </div>
        )}
        {type === 'multi' && (
          <React.Fragment>
            <div className={classes.multiResponseContainer}>
              {multiResponses.map(({ prefix, description }) => (
                <React.Fragment key={responseId + prefix}>
                  <Typography className={classes.bold} variant="body2">
                    {prefix}
                  </Typography>
                  <Typography variant="body2">{description}</Typography>
                </React.Fragment>
              ))}
            </div>
            <Typography
              variant="body2"
              color={response.notes?.trim() ? 'textPrimary' : 'textSecondary'}
              className={classes.marginTop}
            >
              Notes: {response.notes?.trim() || '(Empty notes)'}
            </Typography>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

interface JurorResponseDetailProps {
  juror?: { juror_number: number; juror_id: string; first_name: string; last_name: string };
  onClick?: Function;
  response?: any;
  responseId?: string;
  type?: any;
  options?: any;
}

const JurorResponseDetail: React.FC<JurorResponseDetailProps> = ({
  juror,
  onClick = () => {},
  response,
  responseId,
  type,
  options,
}) => {
  const classes = useStyles();

  if (!juror) return <span />;

  const { juror_number, first_name, last_name } = juror;

  const handleClick = (event) => {
    onClick(juror.juror_id);
  };

  let multiResponses = [];

  if (type === 'multi') {
    for (let [choice, value] of Object.entries(response)) {
      if (!value) continue;
      if (choice === 'notes') continue;
      multiResponses.push(options.choices[Number(choice)]);
    }
  }

  return (
    <>
      <ButtonBase className={cx(classes.responseHeading)} onClick={handleClick}>
        <Typography className={classes.grow} variant="body1">
          <strong>{juror_number}</strong>: {`${last_name}, ${first_name}`}
        </Typography>
      </ButtonBase>
      <div className={classes.responseBody}>
        {type === 'freetext' && (
          <Typography
            variant="body2"
            color={response.text.trim() ? 'textPrimary' : 'textSecondary'}
          >
            {response.text.trim() || '(Empty response)'}
          </Typography>
        )}
        {type === 'scale' && (
          <div className={classes.scaleResponseContainer}>
            <Typography className={classes.bold} variant="body2">
              Value
            </Typography>
            <Typography variant="body2">
              {response.value} / {options.max}
            </Typography>
            <Typography className={classes.bold} variant="body2">
              Notes
            </Typography>
            <Typography
              variant="body2"
              color={response.notes?.trim() ? 'textPrimary' : 'textSecondary'}
            >
              {response.notes?.trim() || '(Empty notes)'}
            </Typography>
          </div>
        )}
        {type === 'multi' && (
          <React.Fragment>
            <div className={classes.multiResponseContainer}>
              {multiResponses.map(({ prefix, description }) => (
                <React.Fragment key={responseId + prefix}>
                  <Typography className={classes.bold} variant="body2">
                    {prefix}
                  </Typography>
                  <Typography variant="body2">{description}</Typography>
                </React.Fragment>
              ))}
            </div>
            <Typography
              variant="body2"
              color={response.notes?.trim() ? 'textPrimary' : 'textSecondary'}
              className={classes.marginTop}
            >
              Notes: {response.notes?.trim() || '(Empty notes)'}
            </Typography>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

interface ResponseDetailProps {
  variant: 'question' | 'juror';
}

const ResponseDetail: React.FC<
  ResponseDetailProps & QuestionResponseDetailProps & JurorResponseDetailProps
> = ({ variant, ...props }) => {
  switch (variant) {
    case 'question':
      // @ts-ignore
      return <QuestionResponseDetail {...props} />;
    case 'juror':
    default:
      // @ts-ignore
      return <JurorResponseDetail {...props} />;
  }
};

export default ResponseDetail;
