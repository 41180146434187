import { IQuestion } from 'state/interfaces';

export const questionsAsObject = (questions: IQuestion[]) =>
  questions.reduce((acc, question) => {
    acc[question.id] = question;
    return acc;
  }, {});

export const questionsByParty = (questions: IQuestion[]) =>
  questions.reduce((acc, question) => {
    if (!acc[question.asking_party]) {
      acc[question.asking_party] = [];
    }
    acc[question.asking_party].push(question);
    return acc;
  }, {});
