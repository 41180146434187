import React from 'react';
import { Typography, Card, CardContent, TextField } from '@material-ui/core';

interface FreetextResponseFormProps {
  question: {
    question: string;
    options: {
      text: string;
    };
  };
  initialResponse?: {
    text: string;
  };
  onUpdate: Function;
}

const FreetextResponseFormProps: React.FC<FreetextResponseFormProps> = ({
  question,
  initialResponse,
  onUpdate,
}) => {
  const [text, setText] = React.useState(initialResponse?.text ?? question.options.text);
  const isMountingRef = React.useRef(true);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isMountingRef.current) {
      timeout = setTimeout(() => {
        onUpdate({ text });
      }, 500);
    } else {
      timeout = setTimeout(() => {}, 500);
      isMountingRef.current = false;
    }

    return () => clearTimeout(timeout);
  }, [text, onUpdate]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {question.question}
        </Typography>
        <TextField
          variant="outlined"
          rows={6}
          rowsMax={6}
          fullWidth
          value={text}
          onChange={handleChange}
          multiline
        />
      </CardContent>
    </Card>
  );
};

export default React.memo(FreetextResponseFormProps);
