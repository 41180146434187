import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {
  Chat as FreeTextIcon,
  List as MultiIcon,
  LinearScale as ScaleIcon,
  Delete,
} from '@material-ui/icons';
import { AskingPartyBadger } from 'components';
import { useGetQuestionsByCaseId, useRemoveQuestion } from 'hooks/queries/useQuestionsQueries';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { activeQuestion } from 'store';

const useStyles = makeStyles(theme => ({
  icon: {
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const questionsIcons = {
  freetext: FreeTextIcon,
  multi: MultiIcon,
  scale: ScaleIcon,
};

interface IQuestionsListProps {
  onSelectedQuestion: Function;
}

const QuestionsList: React.FC<IQuestionsListProps> = ({ onSelectedQuestion }) => {
  const classes = useStyles();
  const { caseId } = useParams();
  const [deleting, setDeleting] = React.useState({});
  const { data: questionsData, refetch } = useGetQuestionsByCaseId(caseId);
  const [currentQuestion, setCurrentQuestion] = useAtom(activeQuestion);

  const questions = useMemo(() => (questionsData ? questionsData : []), [questionsData]);

  const handleClickFactory = (id: string) => () => {
    //@ts-ignore
    setCurrentQuestion(questions.find(question => question.id === id));
    onSelectedQuestion();
  };

  const deleteQuestion = useRemoveQuestion(caseId);
  const handleDeleteFactory = (questionId: string) => () => {
    deleteQuestion
      .mutateAsync({ questionId })
      .then(() => refetch())
      .catch(err => {
        // console.log(err);
        setDeleting(deleting => ({ ...deleting, [questionId]: false }));
      });
    setDeleting(deleting => ({ ...deleting, [questionId]: true }));
  };

  return (
    <List>
      {questions.map((question, i) => {
        const Icon = questionsIcons[question.type];
        return (
          <ListItem
            key={question.id}
            className={classes.listItem}
            divider
            button
            selected={currentQuestion?.id === question.id}
            disabled={deleting[question.id]}
            onClick={handleClickFactory(question.id)}
          >
            <ListItemIcon className={classes.icon}>
              <AskingPartyBadger size="small" party={question.asking_party} />
              <Icon />
            </ListItemIcon>
            <ListItemText primary={`Q${i + 1}. ${question.question}`}></ListItemText>
            <ListItemSecondaryAction>
              {!deleting[question.id] && (
                <IconButton onClick={handleDeleteFactory(question.id)}>
                  <Delete />
                </IconButton>
              )}
              {deleting[question.id] && <CircularProgress />}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default React.memo(QuestionsList);
