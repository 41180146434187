import { IStrike } from 'state/interfaces';

export const isJurorStruck = (strikes: IStrike[]) =>
  strikes.reduce((acc, strike) => {
    acc[strike.juror_id] = true;
    return acc;
  }, {});

export const strikesByJuror = (strikes: IStrike[]) =>
  strikes.reduce((acc, strike) => {
    if (!acc[strike.juror_id]) {
      acc[strike.juror_id] = {};
    }
    acc[strike.juror_id][strike.striking_party] = strike;
    return acc;
  }, {});

export const strikesByPartyMap = (strikes: IStrike[]) =>
  strikes.reduce((acc, strike) => {
    if (!acc[strike.striking_party]) {
      acc[strike.striking_party] = {};
    }
    acc[strike.striking_party][strike.juror_id] = strike;
    return acc;
  }, {});

export const strikesByParty = (strikes: IStrike[]) =>
  strikes.reduce((acc, strike) => {
    if (!acc[strike.striking_party]) {
      acc[strike.striking_party] = [];
    }
    acc[strike.striking_party].push(strike);
    return acc;
  }, {});

export const strikesByQuestion = (strikes: IStrike[]) =>
  strikes.reduce((acc, strike) => {
    if (!strike.question_id) return acc;
    if (!acc[strike.question_id]) {
      acc[strike.question_id] = { jurors: {} };
    }
    acc[strike.question_id].jurors[strike.juror_id] = strike;
    return acc;
  }, {});

export const strikesByQuestionMap = (strikes: IStrike[], question_id: string) =>
  strikes.filter(strike => strike.question_id === question_id);
