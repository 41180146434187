import React from 'react';
import Echarts from 'echarts-for-react';
import { sentenceCase } from 'change-case';
import EmptyState from './EmptyState';
import { differenceInYears } from 'date-fns';
import { IJuror } from 'state/interfaces';

function resolveLabel(label: string) {
  if (label === 'true') return 'Yes';
  if (label === 'false') return 'No';
  return sentenceCase(label);
}

function getOptionByDemographic(jurors: IJuror[], demographic: string) {
  let seriesData;
  if (demographic !== 'age') {
    const byDemographic = jurors.reduce((acc, juror) => {
      const demValue = juror[demographic];
      if (!demValue && typeof demValue !== 'boolean') return acc;
      if (!acc[demValue]) {
        acc[demValue] = 1;
      } else {
        acc[demValue] += 1;
      }
      return acc;
    }, {});

    seriesData = Object.entries(byDemographic).map(([name, value]) => ({
      name: resolveLabel(name),
      value,
    }));
  } else {
    const jurorAges = jurors
      .filter(juror => juror.date_of_birth)
      .map(juror => differenceInYears(new Date(), new Date(juror.date_of_birth)));

    seriesData = [
      {
        name: 'Under 20',
        value: jurorAges.filter(age => age < 20).length,
      },
      {
        name: '21-30',
        value: jurorAges.filter(age => age >= 21 && age <= 30).length,
      },
      {
        name: '31-40',
        value: jurorAges.filter(age => age >= 31 && age <= 40).length,
      },
      {
        name: '41-50',
        value: jurorAges.filter(age => age >= 41 && age <= 50).length,
      },
      {
        name: '51-60',
        value: jurorAges.filter(age => age >= 51 && age <= 60).length,
      },
      {
        name: '61-70',
        value: jurorAges.filter(age => age >= 61 && age <= 70).length,
      },
      {
        name: '71+',
        value: jurorAges.filter(age => age >= 71).length,
      },
    ].filter(dataPoint => dataPoint.value);
  }

  return {
    display: Boolean(seriesData.length),
    option: {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)',
      },
      series: [
        {
          name: 'Ethnicities',
          type: 'pie',
          radius: '70%',
          center: ['50%', '50%'],
          data: seriesData,
        },
      ],
    },
  };
}

const DemographicChart = ({ jurors, demographic }) => {
  const { display, option } = getOptionByDemographic(jurors, demographic);
  if (!display) return <EmptyState>No data found for this demographic.</EmptyState>;
  return <Echarts option={option} />;
};

export default React.memo(DemographicChart);
