// @ts-nocheck
import React from 'react';
import { debounce } from 'throttle-debounce';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, Container, Grid, Fab, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useAuth } from 'utils/auth';
import { CaseCard } from 'components';
import { SidebarLayout } from 'components/layouts';
import { useDocumentTitle } from 'utils/document';
import { useGetAllCases } from 'hooks/queries/useCaseQueries';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: 32,
    right: 32,
  },
}));

function Cases() {
  useDocumentTitle('Your Cases | Second Chair');
  useAuth({ required: true });
  const classes = useStyles();
  const { status, data: cases } = useGetAllCases();
  const [searchFilter, setSearchFilter] = React.useState<string>('');

  let filteredCases = cases;
  if (searchFilter) {
    filteredCases = cases.filter(caseObj => {
      const searchRegex = new RegExp(`.*${searchFilter}.*`, 'i');
      return (
        searchRegex.test(caseObj.description) ||
        searchRegex.test(caseObj.plaintiff) ||
        searchRegex.test(caseObj.defendant) ||
        searchRegex.test(caseObj.case_number.toString())
      );
    });
  }

  const handleSearch = debounce(500, searchTerm => setSearchFilter(searchTerm));

  return (
    <SidebarLayout onSearch={handleSearch}>
      <Container>
        <Box
          mt={{
            xs: 4,
            sm: 4,
            md: 8,
          }}
        >
          <Typography variant="h4" component="h2" color="textPrimary" gutterBottom>
            Your Cases
          </Typography>
        </Box>
        <Box mt={4} mb={8}>
          {status === 'loading' && <span>Loading...</span>}
          {status === 'success' &&
            (filteredCases.length ? (
              <Grid container spacing={4}>
                {filteredCases.map(el => (
                  <Grid key={el.id} item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <CaseCard {...el} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography component="p" align="center" variant="subtitle1">
                No cases found.
              </Typography>
            ))}
        </Box>
        <Fab
          component={Link}
          className={classes.fab}
          to="/cases/add"
          color="secondary"
          size="large"
          aria-label="add"
        >
          <AddIcon fontSize="large" />
        </Fab>
      </Container>
    </SidebarLayout>
  );
}

export default Cases;
