import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { CustomSlider } from 'components';

const useStyles = makeStyles(theme => ({
  choiceContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
    '&:not(:first-child)': {
      marginTop: theme.spacing(3),
    },
  },
  prefix: {
    width: 70,
  },
  grow: {
    flexGrow: 1,
  },
}));

type ScaleQuestionState = {
  notes: string;
  max: number;
  default: number;
};

interface ScaleQuestionFormProps {
  options?: any;
  onUpdate?: Function;
}

const initialState = {
  notes: '',
  max: 5,
  default: 1,
};

const ScaleQuestionForm = React.forwardRef(
  ({ onUpdate = () => {}, options }: ScaleQuestionFormProps, ref) => {
    const classes = useStyles();
    const [state, setState] = React.useState<ScaleQuestionState>(options || initialState);

    React.useImperativeHandle(ref, () => ({
      getQuestionOptions: () => ({ ...state }),
    }));

    const handleChange = e => {
      let value = e.target.value;
      if (e.target.name === 'max') {
        value = parseInt(value);
      }
      const newState = { ...state, [e.target.name]: value };
      setState(newState);
      onUpdate(newState);
    };

    const handleDefaultChange = (e, newValue) => {
      const newState = { ...state, default: newValue };
      setState(newState);
      onUpdate(newState);
    };

    return (
      <>
        <div className={classes.choiceContainer}>
          {state.max ? (
            <CustomSlider
              defaultValue={1}
              value={state.default}
              onChange={handleDefaultChange}
              className={classes.grow}
              min={1}
              max={state.max}
              step={1}
              marks
            />
          ) : (
            <div className={classes.grow} />
          )}
          <TextField
            value={state.max.toString()}
            onChange={handleChange}
            type="number"
            variant="outlined"
            label="Max"
            name="max"
          />
        </div>
        <div className={classes.choiceContainer}>
          <TextField
            name="notes"
            value={state.notes}
            onChange={handleChange}
            label="Notes"
            variant="outlined"
            fullWidth
            multiline
            placeholder="Enter text to be prefilled when answering"
          />
        </div>
      </>
    );
  },
);

export default ScaleQuestionForm;
