import * as queryString from 'query-string';
import Resource from './Resource';
import { resolve } from '../url';

type RetrieveTokenClient = () => string | void;

enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

const noop = () => null;
const ctx = this;

class Client implements ApiClient {
  baseUrl: string;
  getClientToken: RetrieveTokenClient;
  cases: (id?: string) => Resource;
  private fetch: <R>(url, options) => Promise<R>;

  constructor(fetch, options: ApiClientConstructor) {
    this.fetch = fetch.bind(ctx);
    const baseUrl = options.baseUrl || '/';
    this.baseUrl = baseUrl;
    this.getClientToken = options.getClientToken || noop;

    this.init();
  }

  init = () => {
    this.cases = Resource.create(this, 'cases', {
      methods: ['get', 'post'],
      children: {
        strikes: Resource.createChild(this, 'strikes', {
          methods: ['get', 'post', 'delete'],
        }),
        confirmations: Resource.createChild(this, 'confirmations', {
          methods: ['get'],
        }),
        questions: Resource.createChild(this, 'questions', {
          methods: ['get', 'post', 'patch', 'delete'],
          children: {
            responses: Resource.createChild(this, 'responses', { methods: ['get', 'delete'] }),
            respond: Resource.createChild(this, 'respond', { methods: ['post', 'patch'] }),
          },
        }),
        responses: Resource.createChild(this, 'responses', { methods: ['get'] }),
        jurors: Resource.createChild(this, 'jurors', {
          methods: ['get', 'patch', 'post'],
          children: {
            confirmation: Resource.createChild(this, 'confirmation', { methods: ['patch'] }),
            reorder: Resource.createChild(this, 'reorder', { methods: ['post'] }),
            populate: Resource.createChild(this, 'populate', { methods: ['post'] }),
          },
        }),
      },
    });
  };

  request = <R>({
    url,
    method = HttpMethod.Get,
    headers = {},
    body = null,
    query = null,
    responseType = 'json',
  }: RequestConfig): Promise<R> => {
    let _url = resolve(this.baseUrl, url);
    let _query = query ? queryString.stringify(query) : '';
    let token = this.getClientToken();

    headers['Content-Type'] = 'application/json';
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return this.fetch(`${_url}${_query ? '?' + _query : ''}`, {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    }).then(res => res[responseType]<R>());
  };
}

export default Client;
