import { IConfirmation } from 'state/interfaces';

export const confirmationByJuror = (confirmations: IConfirmation[]) =>
  confirmations.reduce((acc, confirmation) => {
    acc[confirmation.juror_id] = confirmation;
    return acc;
  }, {});

export const confirmationByState = (confirmations: IConfirmation[]) =>
  confirmations.reduce(
    (acc, confirmation) => {
      if (confirmation.state === null) return acc;
      if (!acc[confirmation.state]) {
        acc[confirmation.state] = [];
      }
      acc[confirmation.state].push(confirmation);
      return acc;
    },
    { confirm: [], deny: [] },
  );
