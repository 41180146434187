import { IJuror } from 'state/interfaces';

export const sortJurors = (jurors: IJuror[]) =>
  jurors.slice().sort((a, b) => a.juror_number - b.juror_number);

export const mapJurorsAsObject = (jurors: IJuror[]) => {
  const sorted = sortJurors(jurors);
  return sorted.reduce((acc, curr) => {
    acc[curr.juror_number] = curr;
    return acc;
  }, {});
};

export const reverseSortJurors = (jurors: IJuror[]) => {
  const sorted = sortJurors(jurors);
  return sorted.slice().reverse();
};

export const jurorsByEthnicity = (jurors: IJuror[]) =>
  jurors.reduce((acc, juror) => {
    if (!juror.race) return acc;
    if (!acc[juror.race]) {
      acc[juror.race] = 1;
    } else {
      acc[juror.race] += 1;
    }
    return acc;
  }, {});

export const jurorsByEducation = (jurors: IJuror[]) =>
  jurors.reduce((acc, juror) => {
    if (!juror.education) return acc;
    if (!acc[juror.education]) {
      acc[juror.education] = 1;
    } else {
      acc[juror.education] += 1;
    }
    return acc;
  }, {});
