import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 1000 * 60,
      staleTime: 1000 * 60,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
    },
  },
});
export default queryClient;
