import React, { useMemo } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';
import { Card, CardActionArea, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { AskingPartyBadger } from 'components';
import { useGetCase } from 'hooks/queries/useCaseQueries';
import { useGetAllJurorsByCaseId } from 'hooks/queries/useJurorQueries';
import { useGetConfirmations } from 'hooks/queries/useConfirmationQueries';
import { useGetAllStrikes } from 'hooks/queries/useStrikeQueries';
import {
  confirmationByJuror,
  confirmationByState,
  reverseSortJurors,
  strikesByJuror,
} from 'state/reducers';

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(15, minmax(1.75rem, 1fr))',
    gridAutoRows: '1fr',
    '&::before': {
      content: '""',
      width: 0,
      paddingBottom: '100%',
      gridRow: '1 / 1',
      gridColumn: '1 / 1',
    },
  },
  item: {
    padding: theme.spacing(0.25),
    transform: 'scale(110%)',
    '&:first-child': {
      gridRow: '1 / 1',
      gridColumn: '1 / 1',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '55% 45%',
    },
  },
  strikeZone: {
    background: deepPurple[50],
  },
  faded: {
    opacity: 0.7,
  },
  card: {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: 'none',
    transform: 'scale(90%)',
  },
  cardAction: {
    padding: theme.spacing(1),
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  popover: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *:first-child': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  deny: {
    background: theme.palette.grey[600],
    color: theme.palette.primary.contrastText,
  },
  confirm: {
    background: 'white',
    '&:before': {
      content: '"✕"',
      fontSize: '3rem',
      pointerEvents: 'none',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: theme.palette.grey[500],
      opacity: 0.25,
      [theme.breakpoints.down('md')]: {
        fontSize: '3rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '4rem',
      },
    },
  },
  overlay: {
    zIndex: 1,
  },
}));

const StrikeCard = ({ juror, strikeZone, strikes, onClick, confirmationState, ...props }) => {
  const classes = useStyles();

  const handleClick = () => {
    onClick(juror.juror_id);
  };

  return (
    <div {...props} className={classnames(classes.item, strikeZone && classes.strikeZone)}>
      <Card
        variant="outlined"
        className={classnames(
          classes.card,
          classes.overlay,
          confirmationState === 'confirm' && classes.confirm,
          confirmationState === 'deny' && classes.deny,
        )}
      >
        <CardActionArea onClick={handleClick} className={classes.cardAction}>
          <Typography variant="body2" gutterBottom>
            {juror.juror_number}
          </Typography>
          {strikes &&
            Object.keys(strikes).map((party) => (
              <AskingPartyBadger
                className={classes.overlay}
                size="tiny"
                party={party}
                key={juror.juror_id + party + 'label'}
              />
            ))}
        </CardActionArea>
      </Card>
    </div>
  );
};

interface JurorStrikesOverviewProps {
  onJurorClick?: (jurorId: string) => void;
  preStrikes?: number;
}

function isNullOrUndefined(val) {
  return val === null || val === undefined || val === '';
}

function getStrikeZoneCount(confirmations, denied, preStrikes, jurySize): number {
  if (
    isNullOrUndefined(confirmations) ||
    isNullOrUndefined(preStrikes) ||
    isNullOrUndefined(denied) ||
    !jurySize
  )
    return 0;

  return jurySize + confirmations + 2 * preStrikes;
}

const JurorStrikesOverview: React.FC<JurorStrikesOverviewProps> = ({
  onJurorClick = () => {},
  preStrikes,
}) => {
  const classes = useStyles();
  const { caseId } = useParams();

  const { data: caseData } = useGetCase(caseId);
  const { data: jurorsData } = useGetAllJurorsByCaseId(caseId);
  const { data: confirmationData } = useGetConfirmations(caseId);
  const { data: strikesData } = useGetAllStrikes(caseId);

  const caseResponse = useMemo(() => (caseData ? caseData : null), [caseData]);
  const reversedJurors = useMemo(
    () => (jurorsData ? reverseSortJurors(jurorsData) : []),
    [jurorsData],
  );
  const confirmationStates = useMemo(
    () => (confirmationData ? confirmationByState(confirmationData) : []),
    [confirmationData],
  );
  const confirmationJuror = useMemo(
    () => (confirmationData ? confirmationByJuror(confirmationData) : []),
    [confirmationData],
  );
  const strikesJuror = useMemo(
    () => (strikesData ? strikesByJuror(strikesData) : []),
    [strikesData],
  );

  const confirmedStrikes = confirmationStates?.['confirm'] ?? [];
  const deniedStrikes = confirmationStates?.['deny'] ?? [];
  const jurySize = caseResponse?.jury_size;

  let strikeZoneCount = React.useMemo(() => {
    return getStrikeZoneCount(confirmedStrikes.length, deniedStrikes.length, preStrikes, jurySize);
  }, [jurySize, confirmedStrikes.length, deniedStrikes.length, preStrikes]);

  return (
    <div className={classes.grid}>
      {reversedJurors?.map((juror) => {
        const confirmation = confirmationJuror?.[juror?.juror_id] ?? null;
        let strikeZone = false;
        if (juror.juror_number <= strikeZoneCount && strikeZoneCount > 0) {
          strikeZone = true;
          strikeZoneCount -= 1;
        }

        return (
          <StrikeCard
            key={juror.juror_id}
            strikeZone={strikeZone}
            juror={juror}
            strikes={strikesJuror?.[juror.juror_id]}
            onClick={onJurorClick}
            confirmationState={confirmation?.state}
          />
        );
      })}
    </div>
  );
};

export default JurorStrikesOverview;
