import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Popover, IconButton, Typography } from '@material-ui/core';
import {
  Report as StrikeFilledIcon,
  ReportOutlined as StrikeUnfilledIcon,
} from '@material-ui/icons';
import { AskingPartyBadger } from 'components';

const askingParties = ['prosecution', 'defense', 'judge'];

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *:first-child': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  iconButton: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

interface StrikeIconButtonProps {
  label?: string;
  strike?: boolean;
  size?: 'small' | 'medium';
  onStrikeClick: Function;
  onStrikeDelete: Function;
}

const StrikeIconButton: React.FC<StrikeIconButtonProps> = ({
  label = '',
  strike = false,
  size = 'medium',
  onStrikeClick,
  onStrikeDelete,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    strike ? onStrikeDelete() : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createStrikeHandler = (party) => () => {
    strike ? onStrikeDelete() : onStrikeClick(party);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Popover
        classes={{
          paper: classes.popover,
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Typography variant="body1">
          <strong>{label}</strong>
        </Typography>
        {askingParties.map((party) => (
          <IconButton
            className={classes.iconButton}
            size="small"
            key={party}
            onClick={createStrikeHandler(party)}
          >
            <AskingPartyBadger party={party} />
          </IconButton>
        ))}
      </Popover>
      <IconButton color="secondary" onClickCapture={handleClick} size={size}>
        {strike ? <StrikeFilledIcon /> : <StrikeUnfilledIcon />}
      </IconButton>
    </>
  );
};

export default StrikeIconButton;
