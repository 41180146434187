import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'utils/auth';

const Home = () => {
  useAuth({ required: true });

  return (
    <Redirect
      to={{
        pathname: '/cases',
      }}
    />
  );
};

export default Home;
