import { useQuery, useMutation, QueryClient } from 'react-query';
import { IQuestion } from 'state/interfaces';
import api from 'utils/api';

const queryClient = new QueryClient();

export const addQuestionApi = (caseId: string) => (
  payload: Partial<IQuestion>,
): Promise<IQuestion> =>
  api
    .cases(caseId)
    .questions()
    .post(payload);

export const deleteQuestionById = (caseId: string) => ({
  questionId,
}: {
  questionId: string;
}): Promise<IQuestion> =>
  api
    .cases(caseId)
    .questions(questionId)
    .delete();

export const updateQuestionById = (caseId: string) => ({
  questionId,
  payload,
}: {
  questionId: string;
  payload: Partial<IQuestion>;
}): Promise<IQuestion> =>
  api
    .cases(caseId)
    .questions(questionId)
    .patch(payload);

export const fetchQuestions = (caseId: string) => async (): Promise<IQuestion[]> => {
  return await api
    .cases(caseId)
    .questions()
    .get();
};

export function useGetQuestionsByCaseId(caseId: string) {
  return useQuery({
    queryKey: ['questions', caseId],
    queryFn: fetchQuestions(caseId),
  });
}

export function useAddQuestion(caseId: string) {
  return useMutation({
    mutationFn: addQuestionApi(caseId),
    onSuccess: data => {
      queryClient.invalidateQueries(['questions', caseId]);
    },
  });
}

export function useUpdateQuestion(caseId: string) {
  return useMutation({
    mutationFn: updateQuestionById(caseId),
    onSuccess: () => {
      queryClient.invalidateQueries(['questions', caseId]);
    },
  });
}

export function useRemoveQuestion(caseId: string) {
  return useMutation({
    mutationFn: deleteQuestionById(caseId),
    onSuccess: () => {
      queryClient.invalidateQueries(['questions', caseId]);
    },
  });
}
