import React, { MouseEvent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { ViewList as ListViewIcon, ViewModule as GridViewIcon } from '@material-ui/icons';
import {
  VoirDireToolbar,
  VoirDireJurorsGrid,
  VoirDireJurorsList,
  JurorDetail,
  EmptyState,
  ResponseController,
} from 'components';
import { useAtom } from 'jotai';
import { activeJuror, activeQuestion } from 'store/globalStore';
import { useAddStrike, useGetAllStrikes, useRemoveStrike } from 'hooks/queries/useStrikeQueries';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '60% 40%',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '55% 45%',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '750px auto',
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: '1000px auto',
    },
    gridTemplateRows: 'auto 100%',
    gridTemplateAreas: `
      "header header"
      "jurors detail"
    `,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    paddingBottom: theme.spacing(8),
    flexGrow: 1,
  },
  jurorPanel: {
    height: '100%',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  detailPanel: {
    overflow: 'auto',
    flexGrow: 1,
    padding: theme.spacing(3),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  viewSelection: {
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    background: theme.palette.background.default,
    zIndex: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  jurorsContainer: {
    overflowY: 'scroll',
    height: '100%',
    paddingBottom: theme.spacing(12),
  },
}));

type VoirDireView = 'grid' | 'list';

const VoirDire = () => {
  const classes = useStyles();
  const { caseId } = useParams();
  const [currentJuror] = useAtom(activeJuror);
  const [currentQuestion] = useAtom(activeQuestion);
  const { data: strikeData, refetch: refetchStrikes } = useGetAllStrikes(caseId);
  const [view, setView] = React.useState<VoirDireView>('grid');

  const strikesCase = useMemo(() => (strikeData ? strikeData : []), [strikeData]);

  const addStrike = useAddStrike(caseId);
  const createStrikeHandler = (striking_party: string) => {
    addStrike
      .mutateAsync({
        juror_id: currentJuror.juror_id,
        striking_party,
        question_id: currentQuestion?.id,
      })
      .then(() => refetchStrikes());
  };

  const removeStrike = useRemoveStrike(caseId);
  const createStrikeDeleteHandler = () => {
    const strike = strikesCase.find(strike => strike.juror_id === currentJuror.juror_id);
    if (strike) {
      removeStrike.mutateAsync(strike.strike_id).then(() => {
        refetchStrikes();
      });
    }
  };

  const handleViewChange = (e: MouseEvent, newValue) => {
    if (newValue) {
      setView(newValue);
    }
  };

  const showDetail = useMemo(() => currentJuror && currentQuestion, [
    currentJuror,
    currentQuestion,
  ]);

  return (
    <div className={classes.container}>
      <VoirDireToolbar />
      <div className={classes.jurorPanel}>
        <div className={classes.viewSelection}>
          <ToggleButtonGroup exclusive value={view} onChange={handleViewChange}>
            <ToggleButton value="grid">
              <GridViewIcon className={classes.icon} /> Grid
            </ToggleButton>
            <ToggleButton value="list">
              <ListViewIcon className={classes.icon} /> List
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className={classes.jurorsContainer}>
          {view === 'grid' && <VoirDireJurorsGrid />}
          {view === 'list' && <VoirDireJurorsList />}
        </div>
      </div>
      <div className={classes.detailPanel}>
        {showDetail ? (
          <>
            <JurorDetail
              key={currentJuror?.juror_id || 'default-juror'}
              juror={currentJuror}
              onStrike={createStrikeHandler}
              onStrikeDelete={createStrikeDeleteHandler}
              strike={strikesCase.some(strike => strike.juror_id === currentJuror.juror_id)}
            />
            <ResponseController />
          </>
        ) : (
          <EmptyState>Select a Juror to enter a response for this question</EmptyState>
        )}
      </div>
    </div>
  );
};

export default VoirDire;
