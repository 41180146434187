import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ResponsesPanel, JurorStrikesOverview, StrikesConfirmation } from 'components';
import { useGetCase } from 'hooks/queries/useCaseQueries';
import { useGetAllStrikes } from 'hooks/queries/useStrikeQueries';
import { strikesByParty } from 'state/reducers';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  container: {
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '50% 50%',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '45% 55%',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '45% 55%',
    },
    gridTemplateRows: '100%',
    gridTemplateAreas: `
      "header header"
      "jurors detail"
    `,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    flexGrow: 1,
  },
  strikesPanel: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    height: '100%',
  },
  responsesPanel: {
    height: '100%',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  strikesOverview: {
    maxHeight: 300,
    overflow: 'auto',
    top: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  strikesResolution: {
    height: '100%',
    overflow: 'auto',
    paddingBottom: theme.spacing(28),
  },
  strikesStatusList: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    listStyleType: 'none',
    margin: 0,
    padding: theme.spacing(2),
    position: 'sticky',
    top: 0,
    background: theme.palette.background.default,
    zIndex: 100,
    '& > li': {
      margin: 0,
    },
    '& > li:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

const StrikesStatus = ({
  prosecutionStrikes,
  defenseStrikes,
  judgeStrikes,
  onPreStrikeChange,
  preStrikes,
}) => {
  const classes = useStyles();
  const { caseId } = useParams();
  const { data: caseData } = useGetCase(caseId);
  const caseResponse = useMemo(() => (caseData ? caseData : null), [caseData]);

  const jurySize = caseResponse?.jury_size ?? '...';

  return (
    <ul className={classes.strikesStatusList}>
      <li className={classes.grow}>
        Preemptory strikes:{' '}
        <input
          type="number"
          value={preStrikes}
          onChange={onPreStrikeChange}
          min="0"
          max="10"
          step="1"
        />
      </li>
      <li>Jury size: {jurySize}</li>
      <li>Prosecution strikes: {prosecutionStrikes}</li>
      <li>Defense strikes: {defenseStrikes}</li>
      <li>Judge strikes: {judgeStrikes}</li>
    </ul>
  );
};

const Strikes = () => {
  const classes = useStyles();
  const [preStrikes, setPreStrikes] = React.useState('0');
  const [focusedJurorId, setFocusedJurorId] = React.useState(null);
  const { caseId } = useParams();
  const { data: strikesData } = useGetAllStrikes(caseId);
  const strikes = useMemo(() => (strikesData ? strikesByParty(strikesData) : null), [strikesData]);

  const handlePreStrikesChange = (e) => {
    setPreStrikes(e.target.value);
  };

  const handleJurorClick = (jurorId) => {
    setFocusedJurorId(jurorId);
  };

  return (
    <div className={classes.container}>
      <div className={classes.responsesPanel}>
        <ResponsesPanel focusedJurorId={focusedJurorId} onJurorClick={handleJurorClick} />
      </div>
      <div className={classes.strikesPanel}>
        <div className={classes.strikesOverview}>
          <StrikesStatus
            preStrikes={preStrikes}
            onPreStrikeChange={handlePreStrikesChange}
            prosecutionStrikes={(strikes?.['prosecution'] ?? []).length}
            defenseStrikes={(strikes?.['defense'] ?? []).length}
            judgeStrikes={(strikes?.['judge'] ?? []).length}
          />
          <JurorStrikesOverview
            preStrikes={preStrikes && Number(preStrikes)}
            onJurorClick={handleJurorClick}
          />
        </div>
        <div className={classes.strikesResolution}>
          <StrikesConfirmation />
        </div>
      </div>
    </div>
  );
};

export default Strikes;
