import React, { useMemo } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { sentenceCase } from 'change-case';
import {
  Container,
  Typography,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { EmptyState, DemographicChart, QuestionsChart } from 'components';
import { useGetCase } from 'hooks/queries/useCaseQueries';
import { useGetAllJurorsByCaseId } from 'hooks/queries/useJurorQueries';
import { useGetQuestionsByCaseId } from 'hooks/queries/useQuestionsQueries';
import { questionsByParty } from 'state/reducers';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    zIndex: 0,
    overflow: 'auto',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  description: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2),
  },
  fullWidth: {
    gridColumn: 'span 2',
  },
}));

const Overview = () => {
  const classes = useStyles();
  const { caseId } = useParams();
  const { data: caseData, isLoading: isLoadingCase } = useGetCase(caseId);
  const { data: jurorsData, isLoading: isLoadingJurors } = useGetAllJurorsByCaseId(caseId);
  const { data: questionData, isLoading: isLoadingQuestions } = useGetQuestionsByCaseId(caseId);
  const [demographic, setDemographic] = React.useState<string>('race');

  const handleDemographicChange = e => {
    setDemographic(e.target.value);
  };

  const currentCase = useMemo(() => {
    return caseData ? caseData : null;
  }, [caseData]);

  const jurors = useMemo(() => (jurorsData ? jurorsData : null), [jurorsData]);
  const questions = useMemo(() => (questionData ? questionsByParty(questionData) : null), [
    questionData,
  ]);

  if (isLoadingCase || isLoadingJurors || isLoadingQuestions) {
    return <EmptyState>Loading...</EmptyState>;
  }

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.title}>
          <Typography variant="h4">
            {currentCase.plaintiff} vs. {currentCase.defendant}
          </Typography>
          <Typography variant="h6">Case #{currentCase.case_number}</Typography>
        </div>
        <Typography className={classes.description} variant="body1">
          {currentCase.description}
        </Typography>
        <div className={cx(classes.grid, classes.marginTop)}>
          <Card variant="outlined" className={classes.fullWidth}>
            <CardHeader title="Details" />
            <CardContent className={classes.grid}>
              <Typography variant="body2">
                <strong>Plaintiff</strong>
              </Typography>
              <Typography variant="body2">{currentCase.plaintiff}</Typography>
              <Typography variant="body2">
                <strong>Defendant</strong>
              </Typography>
              <Typography variant="body2">{currentCase.defendant}</Typography>
              <Typography variant="body2">
                <strong>No. of Jurors</strong>
              </Typography>
              <Typography variant="body2">{jurors.length}</Typography>
              <Typography variant="body2">
                <strong>Jury Size</strong>
              </Typography>
              <Typography variant="body2">{currentCase.jury_size}</Typography>
              <Typography variant="body2">
                <strong>Your Party</strong>
              </Typography>
              <Typography variant="body2">{sentenceCase(currentCase.self_party)}</Typography>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardHeader
              title={`Jurors by Demographic`}
              action={
                <FormControl variant="filled">
                  <InputLabel id="chart-by-demographic">Demogrpahic</InputLabel>
                  <Select
                    labelId="label-chart-by-demographic"
                    id="chart-by-demographic"
                    value={demographic}
                    onChange={handleDemographicChange}
                  >
                    <MenuItem value="gender">Gender</MenuItem>
                    <MenuItem value="age">Age</MenuItem>
                    <MenuItem value="race">Ethnicity</MenuItem>
                    <MenuItem value="education">Education</MenuItem>
                    <MenuItem value="occupation">Occupation</MenuItem>
                    <MenuItem value="marital_status">Marital Status</MenuItem>
                    <MenuItem value="is_us_citizen">U.S. Citizen</MenuItem>
                    <MenuItem value="has_served_on_criminal_jury">Served on Criminal Jury</MenuItem>
                    <MenuItem value="has_served_on_civil_jury">Served on Civil Jury</MenuItem>
                    <MenuItem value="number_of_children">Number of Children</MenuItem>
                  </Select>
                </FormControl>
              }
            />
            <CardContent>
              <DemographicChart demographic={demographic} jurors={jurors} />
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardHeader title={`Questions by Party`} />
            <CardContent>
              <QuestionsChart questions={questions} />
            </CardContent>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Overview;
