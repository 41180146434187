import React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Slider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5)
    },
    mark: {
        background: theme.palette.grey[300],
        borderRadius: '50%',
        width: theme.spacing(3),
        height: theme.spacing(3),
        transform: 'translate(-50%, -50%)',
        mixBlendMode: 'multiply'
    },
    markLabel: theme.typography.body1,
    valueLabel: theme.typography.body1
}))

const CustomSlider = props => {
    const classes = useStyles(props)
    const { min, max, step } = props

    const marks = React.useMemo(() => {
        let marksArr = []
        for (let i = min; i <= max; i += step) {
            marksArr.push({
                value: i,
                label: i.toString()
            })
        }
        return marksArr
    }, [min, max, step])

    return (
        <div className={classes.container}>

            <Slider {...props} marks={marks} className={classnames(props.className)} classes={{
                mark: classes.mark,
                markLabel: classes.markLabel,
                valueLabel: classes.valueLabel
            }} />
        </div>
    )
}

export default CustomSlider