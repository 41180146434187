import React from 'react';
import cx from 'classnames';
import { capitalCase as titleCase } from 'change-case';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { StrikeButton } from 'components';
import { getAge } from 'utils/date';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(0.5),
  },
  item: {
    // maxHeight: 40
  },
  itemBig: {
    // paddingBottom: theme.spacing(2),
  },
  itemCompact: {
    // paddingBottom: theme.spacing(1),
  },
  label: {
    // marginBottom: theme.spacing(1),
  },
  contentCompact: {
    padding: 0,
  },
  noValue: {
    color: theme.palette.text.disabled,
  },
}));

const jurorLabels = {
  date_of_birth: 'Date of Birth',
  age: 'Age',
  gender: 'Gender',
  race: 'Ethnicity',
  number_of_children: 'Children',
  marital_status: 'Marital Status',
  education: 'Education Level',
  occupation: 'Occupation',
  employer: 'Employer',
  employment_length: 'Employment Length',
  is_us_citizen: 'U.S. Citizen',
  has_been_in_criminal_case: 'Been in Criminal Case?',
  has_served_on_civil_jury: 'Served on Civil Jury?',
  has_served_on_criminal_jury: 'Served on Criminal Jury?',
  has_sustained_medical_injury: 'Sustained Medical Injury?',
  medical_injury_description: 'Injury',
  spouse_name: 'Spouse Name',
  spouse_occupation: 'Spouse Occupation',
  spouse_employer: 'Spouse Employer',
  spouse_employment_length: 'Spouse Employment Length',
};

type Juror = typeof jurorLabels & {
  children: number;
  first_name: string;
  last_name: string;
  juror_number: number;
};

interface JurorDetailProps {
  juror: any;
  onStrike?: Function;
  onStrikeDelete?: Function;
  strike?: boolean;
  compact?: boolean;
}

const CompactRoot: React.FC<any> = ({ children, variant, ...props }) => (
  <div {...props}>{children}</div>
);

const JurorDetail: React.FC<JurorDetailProps> = ({
  juror,
  onStrike,
  onStrikeDelete,
  strike = false,
  compact = false,
}) => {
  const classes = useStyles();

  const Root = compact ? CompactRoot : Card;

  return (
    <Root className={classes.root} variant="outlined">
      {compact ? null : (
        <CardHeader
          action={
            <StrikeButton
              label={`#${juror.juror_number}:`}
              onStrikeClick={onStrike}
              onStrikeDelete={onStrikeDelete}
              strike={strike}
            />
          }
          title={`#${juror.juror_number}: ${juror.first_name ??
            '(Unnamed Juror)'} ${juror.last_name ?? ''}`}
        ></CardHeader>
      )}

      <CardContent className={cx(compact ? classes.contentCompact : null)}>
        <Typography variant="h6"></Typography>
        <div className={classes.grid}>
          {Object.entries(jurorLabels).map(([property, label]) => {
            let value = juror[property];

            if (property.toLowerCase() === 'date_of_birth' && value) {
              value = format(new Date(value), 'MM/dd/yyyy');
            } else if (property.toLowerCase() === 'age') {
              const dob = juror['date_of_birth'];
              if (!dob) {
                value = null;
              }
              value = getAge(dob);
            } else if (typeof value === 'boolean') {
              value = value ? 'Yes' : 'No';
            } else {
              value = value ? titleCase(value.toString()) : null;
            }

            return (
              <div className={classes.label} key={property}>
                <p className={cx(classes.item, compact ? classes.itemCompact : classes.item)}>
                  <strong className={classes.label}>{titleCase(label)}</strong>
                  <br />
                  <span className={cx(juror[property] === null && classes.noValue)}>
                    {juror[property] !== null ? value : 'N/A'}
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </CardContent>
    </Root>
  );
};

export default JurorDetail;
