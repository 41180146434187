import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Container, Typography } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      height: '100vh',
    },
    '#__next': {
      height: '100%',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
}));

export interface ISheetLayout {
  children?: React.ReactNode;
  title?: React.ReactNode;
  actions?: React.ReactChild;
}

const SheetLayout: React.FC<ISheetLayout> = ({ children, title = 'Untitled case', actions }) => {
  const classes = useStyles();

  return (
    <>
      <AppBar elevation={1} color="default" position="fixed" variant="outlined">
        <Toolbar>
          <IconButton
            component={Link}
            to="/cases"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            className={classes.menuButton}
          >
            <BackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {title}
          </Typography>
          {actions}
        </Toolbar>
      </AppBar>
      <Container component="main">
        <div className={classes.toolbar} />
        {children}
      </Container>
    </>
  );
};

export default SheetLayout;
