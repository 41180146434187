import React from 'react';
import { useHistory } from 'react-router-dom';
import cookie from 'js-cookie';

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

type UseAuthHook = (options?: {
  required?: boolean;
}) => {
  login: Function;
  logout: Function;
  token: string;
  isLoggedIn: boolean;
  user: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    iat: number;
  };
};

export const useAuth: UseAuthHook = (options = {}) => {
  const { required } = options;
  const history = useHistory();
  const [token, setToken] = React.useState(cookie.get('token'));

  const login = React.useCallback(
    ({ token }) => {
      cookie.set('token', token);
      setToken(token);
      history.push('/cases');
    },
    [history],
  );

  const logout = React.useCallback(() => {
    cookie.remove('token');
    setToken(null);
    history.push('/login');
  }, [history]);

  const user = React.useMemo(() => {
    if (!token) return null;
    return parseJwt(token);
  }, [token]);

  React.useLayoutEffect(() => {
    if (!required) return;
    if (!token) {
      logout();
    }
  }, [logout, required, token]);

  return {
    token,
    login,
    logout,
    user,
    isLoggedIn: Boolean(token),
  };
};
