// Badger badger badger badger badger badger...
// Mushroom mushroom!

// @ts-nocheck

import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 40,
    width: 40,
    fontSize: '1.2rem',
  },
  small: {
    height: 24,
    width: 24,
    fontSize: '0.9rem',
  },
  tiny: {
    height: 16,
    width: 16,
    fontSize: '0.5rem',
  },
  prosecution: {
    background: theme.palette.prosecution.main,
  },
  defense: {
    background: theme.palette.defense.main,
  },
  judge: {
    background: theme.palette.judge.main,
  },
}));

interface AskingPartyBadgerProps {
  party: string;
  size?: 'tiny' | 'small' | 'medium';
  className?: string;
}

const AskingPartyBadger: React.FC<AskingPartyBadgerProps> = ({ party, size = 'medium', className }) => {
  const classes = useStyles();
  const abbr = party ? party[0].toUpperCase() : '';
  return (
    <Avatar
      alt={party}
      className={classnames(
        classes.root,
        classes[party],
        className,
        size === 'small' && classes.small,
        size === 'tiny' && classes.tiny,
      )}
    >
      {abbr}
    </Avatar>
  );
};

export default AskingPartyBadger;
