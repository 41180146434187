import { IQuestionResponse } from 'state/interfaces';

export const responseObj = (responses: IQuestionResponse[]) =>
  responses.reduce((acc, response: { response_id: string }) => {
    acc[response.response_id] = response;
    return acc;
  }, {});

export const responsesByJuror = (responses: IQuestionResponse[]) =>
  responses.reduce((acc, response: { juror_id: string }) => {
    acc[response.juror_id] = response;
    return acc;
  }, {});

export const responsesByJurorArr = (responses: IQuestionResponse[]) =>
  responses.reduce((acc, response: { juror_id: string }) => {
    if (!acc[response.juror_id]) {
      acc[response.juror_id] = [];
    }
    acc[response.juror_id].push(response);
    return acc;
  }, {});

export const responsesByQuestion = (responses: IQuestionResponse[]) =>
  responses.reduce((acc, response: { question_id: string }) => {
    if (!acc[response.question_id]) {
      acc[response.question_id] = [];
    }
    acc[response.question_id].push(response);
    return acc;
  }, {});
