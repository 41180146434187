import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  choiceContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
    '&:not(:first-child)': {
      marginTop: theme.spacing(3),
    },
  },
  prefix: {
    width: 70,
  },
  grow: {
    flexGrow: 1,
  },
}));

interface FreetextQuestionFormProps {
  options?: any;
  onUpdate?: Function;
}

const FreetextQuestionForm = React.forwardRef(
  ({ onUpdate = () => {}, options }: FreetextQuestionFormProps, ref) => {
    const classes = useStyles();
    const [text, setText] = React.useState<string>(options?.text ?? '');

    React.useImperativeHandle(ref, () => ({
      getQuestionOptions: () => ({ text }),
    }));

    const handleChange = e => {
      const newText = e.target.value;
      setText(newText);
      onUpdate({
        text: newText,
      });
    };

    return (
      <div className={classes.choiceContainer}>
        <TextField
          label="Template"
          value={text}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          multiline
          placeholder="Enter text to be prefilled when answering"
        />
      </div>
    );
  },
);

export default FreetextQuestionForm;
