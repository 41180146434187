import React from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { lightTheme } from './styles/theme';
import { Home, Login, Logout, Signup, Cases, Case, AddCase, NotFound } from 'pages';
import { QueryClientProvider } from 'react-query';
import queryClient from 'utils/queryClient';

const useStyles = makeStyles(() => ({
  '@global': {
    '*, *::before, *::after': {
      boxSizing: 'border-box',
    },
  },
}));

function App() {
  useStyles();
  const theme = lightTheme;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/logout">
                <Logout />
              </Route>
              <Route exact path="/signup">
                <Signup />
              </Route>
              <Route exact path="/cases/add">
                <AddCase />
              </Route>
              <Route path="/cases/:caseId">
                <Case />
              </Route>
              <Route exact path="/cases">
                <Cases />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
