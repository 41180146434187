import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Card, CardActionArea, CardContent, Typography, IconButton } from '@material-ui/core';
import { ChevronRight as ChevronRightIcon, CheckCircle as ResponseIcon } from '@material-ui/icons';
import GREEN from '@material-ui/core/colors/green';
import { StrikeIconButton } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: '80ms ease-in',
    willChange: 'outline',
  },
  actions: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  grow: {
    flexGrow: 1,
  },
  name: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 600,
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    width: '60%',
  },
  active: {
    boxShadow: `0 0 2pt 2pt ${theme.palette.primary.light}`,
  },
  response: {
    marginLeft: theme.spacing(0.25),
    transform: `translateY(2px)`,
  },
  number: {
    fontWeight: 700,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  hint: {
    color: theme.palette.text.hint,
  },
  green: {
    color: GREEN[500],
  },
  strike: {
    position: 'relative',
    '&::before': {
      // zIndex: 1,
      opacity: 0.3,
      content: '"C"',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '100%',
      textAlign: 'center',
      transform: 'translateY(-50%)',
      fontSize: '2.3rem',
      fontWeight: 700,
      pointerEvents: 'none',
    },
    '&::after': {
      display: 'inline',
      verticalAlign: 'middle',
      background: `rgba(0, 0, 0, 0.3)`,
      opacity: 0.3,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
    },
  },
}));

interface JurorCardProps {
  juror_number: number;
  first_name?: string;
  last_name?: string;
  active?: boolean;
  strike?: boolean;
  response?: boolean;
  onSelect?: Function;
  onStrike?: Function;
  onStrikeDelete?: Function;
}

const JurorCard: React.FC<JurorCardProps> = ({
  active = false,
  strike = false,
  response = false,
  juror_number,
  first_name = '',
  last_name = '(Unnamed Juror)',
  onSelect = () => {},
  onStrike = () => {},
  onStrikeDelete = () => {},
}) => {
  const classes = useStyles();

  const handleJurorSelect = () => {
    onSelect(juror_number);
  };

  return (
    <Card
      className={classnames(classes.root, active && classes.active, strike && classes.strike)}
      variant="outlined"
      square
    >
      <CardActionArea component="div" className={classes.grow} onClick={handleJurorSelect}>
        <CardContent className={classes.actions}>
          <Typography
            className={classnames(classes.grow, classes.number, !response && classes.hint)}
            variant="h5"
          >
            {juror_number}
            {response && <ResponseIcon className={classnames(classes.green, classes.response)} />}
          </Typography>
          <StrikeIconButton
            size="small"
            label={`#${juror_number}:`}
            onStrikeClick={onStrike}
            onStrikeDelete={onStrikeDelete}
            strike={strike}
          />
        </CardContent>
        <CardContent className={classes.actions}>
          <Typography className={classnames(classes.grow, classes.name)} variant="body1">
            {`${last_name}${first_name ? ', ' + first_name : ''}`}
          </Typography>
          <IconButton onClick={handleJurorSelect} size="small">
            <ChevronRightIcon />
          </IconButton>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default JurorCard;
