import { useQuery, useMutation, QueryClient } from 'react-query';
import { IStrike } from 'state/interfaces';
import api from 'utils/api';

const queryClient = new QueryClient();

export const fetchStrikes = (caseId: string) => async (): Promise<IStrike[]> => {
  return await api
    .cases(caseId)
    .strikes()
    .get();
};

export const createStrike = (caseId: string) => (payload: Partial<IStrike>): Promise<IStrike> => {
  return api
    .cases(caseId)
    .strikes()
    .post(payload);
};

export const deleteStrike = (caseId: string) => (strikeId: string): Promise<IStrike> => {
  return api
    .cases(caseId)
    .strikes(strikeId)
    .delete();
};

export function useGetAllStrikes(caseId: string) {
  return useQuery({
    queryKey: ['strikes', caseId],
    queryFn: fetchStrikes(caseId),
  });
}

export function useAddStrike(caseId: string) {
  return useMutation({
    mutationFn: createStrike(caseId),
    onSuccess: () => {
      queryClient.invalidateQueries(['strikes', caseId]);
    },
  });
}

export function useRemoveStrike(caseId: string) {
  return useMutation({
    mutationFn: deleteStrike(caseId),
    onSuccess: () => {
      queryClient.invalidateQueries(['strikes', caseId]);
    },
  });
}
