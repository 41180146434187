import React from 'react';
import Typography from '@material-ui/core/Typography';
import Anchor from '../components/Anchor'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Anchor href="/">
        Second Chair Analytics
      </Anchor>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default Copyright
