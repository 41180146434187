import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'utils/auth';

export default () => {
  const { logout } = useAuth();

  React.useLayoutEffect(() => {
    logout();
  }, [logout]);

  return <Redirect to="/login"></Redirect>;
};
