// @ts-nocheck

import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Toolbar, IconButton, Button, ButtonBase, Popover } from '@material-ui/core';
import {
  ChevronLeft as LeftIcon,
  ChevronRight as RightIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import {
  QuestionsIcon,
  AskingPartyBadger,
  QuestionSwapOverlay,
  QuestionAddOverlay,
} from 'components';
import { useGetQuestionsByCaseId, useAddQuestion } from 'hooks/queries/useQuestionsQueries';
import { useAtom } from 'jotai';
import { activeQuestion } from 'store/globalStore';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    gridArea: 'header',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    zIndex: 2,
    transition: 'box-shadow 80ms ease',
    willChange: 'box-shadow',
  },
  question: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
  icon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  text: {
    textAlign: 'left',
  },
  prosecution: {
    boxShadow: `0 0 2pt 2pt ${theme.palette.prosecution.main}`,
  },
  defense: {
    boxShadow: `0 0 2pt 2pt ${theme.palette.defense.main}`,
  },
  judge: {
    boxShadow: `0 0 2pt 2pt ${theme.palette.judge.main}`,
  },
}));

const VoirDireToolbar: React.FC<VoirDireToolbarProps> = () => {
  const classes = useStyles();
  const { caseId } = useParams();
  const [swapAnchorEl, setSwapAnchorEl] = React.useState(null);
  const [addAnchorEl, setAddAnchorEl] = React.useState(null);
  const { data: questionsData, isLoading, refetch } = useGetQuestionsByCaseId(caseId);
  const [currentQuestion, setCurrentQuestion] = useAtom(activeQuestion);

  const questions = useMemo(() => (questionsData ? questionsData : []), [questionsData]);
  const currentIndex = questions.findIndex(question => question.id === currentQuestion?.id);

  React.useEffect(() => {
    if (!questions || questions.length === 0 || currentQuestion) return;
    setCurrentQuestion(questions[0]);
  }, [questions, currentQuestion, setCurrentQuestion]);

  const handlePreviousClick = () => {
    setCurrentQuestion(questions[currentIndex - 1]);
  };

  const handleNextClick = () => {
    setCurrentQuestion(questions[currentIndex + 1]);
  };

  const handleSwapPopoverOpen = event => {
    setSwapAnchorEl(event.currentTarget);
  };

  const handleSwapPopoverClose = () => {
    setSwapAnchorEl(null);
  };

  const handleSwapSelect = () => {
    handleSwapPopoverClose();
  };

  const handleAddPopoverOpen = event => {
    setAddAnchorEl(event.currentTarget);
  };

  const handleAddPopoverClose = () => {
    setAddAnchorEl(null);
  };

  const addQuestion = useAddQuestion(caseId);
  const handleAddSubmit = payload => {
    addQuestion
      .mutateAsync(payload)
      .then(() => {
        refetch();
      })
      .finally(() => handleAddPopoverClose());
  };

  const isDisabled = isLoading || !currentQuestion;
  const isPreviousDisabled = isDisabled || currentIndex <= 0;
  const isNextDisabled = isDisabled || currentIndex + 1 >= questions.length;
  const isSwapPopoverOpen = Boolean(swapAnchorEl);
  const isAddPopoverOpen = Boolean(addAnchorEl);

  return (
    <Toolbar
      className={classnames(classes.toolbar, classes[currentQuestion?.asking_party ?? 'toolbar'])}
    >
      <Button variant="outlined" onClick={handleAddPopoverOpen}>
        <AddIcon />
        {' Add Question'}
      </Button>
      {currentQuestion ? (
        <ButtonBase
          className={classnames(classes.grow, classes.question)}
          onClick={handleSwapPopoverOpen}
        >
          <AskingPartyBadger party={currentQuestion.asking_party} />
          <QuestionsIcon className={classes.icon} type={currentQuestion.type} />
          <Typography className={classnames(classes.grow, classes.text)} variant="subtitle1">
            Q{currentIndex + 1}. {currentQuestion.question}
          </Typography>
        </ButtonBase>
      ) : (
        <span className={classes.grow}></span>
      )}
      <Popover
        id="question-swap-popover"
        open={isSwapPopoverOpen}
        anchorEl={swapAnchorEl}
        onClose={handleSwapPopoverClose}
      >
        <QuestionSwapOverlay onSelectedQuestion={handleSwapSelect} />
      </Popover>
      <Popover
        id="question-add-popover"
        open={isAddPopoverOpen}
        anchorEl={addAnchorEl}
        onClose={handleAddPopoverClose}
      >
        <QuestionAddOverlay onSubmit={handleAddSubmit} onCancel={handleAddPopoverClose} />
      </Popover>
      <IconButton disabled={isPreviousDisabled} onClick={handlePreviousClick}>
        <LeftIcon />
      </IconButton>
      <IconButton disabled={isNextDisabled} onClick={handleNextClick}>
        <RightIcon />
      </IconButton>
    </Toolbar>
  );
};

export default VoirDireToolbar;
