import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Box, IconButton, Typography, Theme, Button } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

interface ICaseCard {
  id: string;
  plaintiff?: string;
  defendant?: string;
  description?: string;
  case_number?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    marginTop: 8,
    marginRight: 8,
    float: 'right',
    zIndex: theme.zIndex.modal,
  },
  card: {
    position: 'relative',
  },
  viewBtn: {
    position: 'absolute',
    bottom: 24,
    right: 16,
  },
  grow: {
    flexGrow: 1,
  },
}));

const CaseCard: React.FC<ICaseCard> = ({ id, case_number, plaintiff, defendant, description }) => {
  const classes = useStyles();

  const handleMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Card className={classes.card} elevation={0} variant="outlined">
      <IconButton onClick={handleMenuClick} className={classes.menuButton}>
        <MoreVert />
      </IconButton>
      <Button
        component={Link}
        to={`/cases/${id}`}
        color="default"
        className={classes.viewBtn}
        variant="contained"
        disableElevation
      >
        View
      </Button>
      <CardContent>
        <Box height={200} display="flex" flexDirection="column" justifyContent="space-between">
          <Typography gutterBottom variant="h6">
            {plaintiff} vs. {defendant}
          </Typography>
          {Boolean(case_number) && (
            <Typography className={classes.grow} variant="body2">
              Case No. {case_number}
            </Typography>
          )}
          <div>{description && <Typography variant="subtitle2">{description}</Typography>}</div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CaseCard;
