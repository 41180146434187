import React, { FormEventHandler } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  MenuItem,
  FormControl,
  Grid,
  Typography,
  TextField,
  Button,
  InputLabel,
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import MultiQuestionForm from './MultiQuestionForm';
import FreetextQuestionForm from './FreetextQuestionForm';
import ScaleQuestionForm from './ScaleQuestionForm';

const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: theme.spacing(2),
  },
  choiceContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
    '&:not(:first-child)': {
      marginTop: theme.spacing(3),
    },
  },
  prefix: {
    width: 70,
  },
  grow: {
    flexGrow: 1,
  },
  actions: {
    textAlign: 'right',
    marginTop: theme.spacing(3),
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  askingParty: {
    minWidth: 150,
  },
}));

interface NewQuestionFormProps {
  onSubmit?: Function;
  onCancel?: Function;
}

const questionTypeSubforms = {
  multi: MultiQuestionForm,
  freetext: FreetextQuestionForm,
  scale: ScaleQuestionForm,
};

const NewQuestionForm: React.FC<NewQuestionFormProps> = ({
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const classes = useStyles();
  const subformRef = React.useRef(null);
  const mountedRef = React.useRef(true);
  const [question, setQuestion] = React.useState<string>('');
  const [askingParty, setAskingParty] = React.useState(
    localStorage.getItem('last_asking_party') || 'defense',
  );
  const [type, setType] = React.useState('freetext');
  const [isSubmitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  });

  const handleSubmit: FormEventHandler = e => {
    e.preventDefault();
    const payload = {
      question,
      type,
      asking_party: askingParty,
      options: subformRef.current.getQuestionOptions(),
    };
    const submitReturnValue = onSubmit(payload);
    if (submitReturnValue instanceof Promise) {
      setSubmitting(true);
      submitReturnValue.then(() => mountedRef.current && setSubmitting(false));
    }
  };

  const handleCancel: FormEventHandler = e => {
    onCancel();
  };

  const handleTypeChange = (event, newType) => {
    if (newType) {
      setType(newType);
    }
  };

  const handleAskingPartyChange = event => {
    localStorage.setItem('last_asking_party', event.target.value);
    setAskingParty(event.target.value);
  };

  const QuestionTypeComponent = questionTypeSubforms[type];

  return (
    <form className={classes.form} onSubmit={handleSubmit} onReset={handleCancel}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Add a new question
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Question title"
            name="question"
            value={question}
            onChange={e => setQuestion(e.target.value)}
            size="medium"
            fullWidth
            placeholder="e.g. What is your name?"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.askingParty} variant="outlined">
            <InputLabel>Asking party</InputLabel>
            <Select
              value={askingParty}
              onChange={handleAskingPartyChange}
              defaultValue="defense"
              label="Asking party"
              fullWidth
            >
              <MenuItem value="defense">Defense</MenuItem>
              <MenuItem value="prosecution">Prosecution</MenuItem>
              <MenuItem value="judge">Judge</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <ToggleButtonGroup exclusive value={type} onChange={handleTypeChange}>
            <ToggleButton value="freetext">Free Text</ToggleButton>
            <ToggleButton value="multi">Multiple Choice</ToggleButton>
            <ToggleButton value="scale">Numeric Scale</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <QuestionTypeComponent ref={subformRef} />
        </Grid>
        <Grid className={classes.actions} item xs={12}>
          <Button type="reset" color="primary" variant="outlined" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewQuestionForm;
