import React from 'react';
import Echarts from 'echarts-for-react';
import { ETHNICITIES_BY_VALUE } from 'utils/constants'

function getOption(jurors) {
  const data = Object.entries(jurors).map(([name, value]) => ({ name: ETHNICITIES_BY_VALUE[name], value }));

  return {
    tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
    },
    series: [
      {
        name: 'Ethnicities',
        type: 'pie',
        radius: '90%',
        center: ['50%', '50%'],
        data,
      },
    ],
  };
}

const EthnicityChart: React.FC<{ jurors: any }> = ({ jurors }) => {
  return <Echarts option={getOption(jurors)} />;
};

export default EthnicityChart;
