import { QueryClient, useMutation, useQuery } from 'react-query';
import api from 'utils/api';
import { IConfirmation } from 'state/interfaces';

const queryClient = new QueryClient();

export const fetchAllConfirmations = (caseId: string) => async (): Promise<IConfirmation[]> => {
  return await api.cases(caseId).confirmations().get();
};

export const updateConfirmationState =
  (caseId: string) =>
  (payload: { jurorId: string; state: string }): Promise<IConfirmation> => {
    return api.cases(caseId).jurors(payload.jurorId).confirmation().patch({ state: payload.state });
  };

export function useGetConfirmations(caseId: string) {
  return useQuery({
    queryKey: ['confirmations', caseId],
    queryFn: fetchAllConfirmations(caseId),
  });
}

export function useUpdateConfirmationState(caseId: string) {
  return useMutation({
    mutationFn: updateConfirmationState(caseId),
    onSuccess: () => {
      queryClient.invalidateQueries(['confirmations', caseId]);
    },
  });
}
