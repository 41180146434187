import * as React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import {
  Select,
  FormControl,
  Button,
  Typography,
  TextField,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import {
  STATE_ABBREVIATIONS,
  NUMBER_OF_CHILDREN,
  EDUCATION_LEVELS,
  ETHNICITIES,
} from 'utils/constants';
import { getAge } from 'utils/date';
import { fetchCode, useSearchByNameHCAD } from 'hooks/queries/useHCADQueries';
import { HcadData } from 'state/interfaces';
import { debounce } from 'lodash';
import { splitName } from 'utils/text';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'grid',
    gridAutoFlow: 'rows',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: theme.spacing(2),
  },
  formItem: {},
  formItemFull: {
    gridColumn: 'span 4',
  },
  formItemHalf: {
    gridColumn: 'span 2',
  },
  form: {
    paddingTop: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  prefix: {
    width: 70,
  },
  grow: {
    flexGrow: 1,
  },
  actions: {
    textAlign: 'right',
    marginTop: theme.spacing(3),
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  askingParty: {
    minWidth: 150,
  },
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface EditJurorFormProps {
  juror: any;
  onJurorUpdate: (juror_id: string, updates: JurorState) => Promise<unknown>;
  jurorNumber: number;
  onNextJuror: Function;
  onPreviousJuror: Function;
  disableNextJuror: boolean;
  disablePreviousJuror: boolean;
}

interface JurorState {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  children?: number;
  mailing_address?: string;
  city?: string;
  county?: string;
  state?: string;
  phone_number?: string;
  date_of_birth?: Date;
  has_sustained_medical_injury?: 'yes' | 'no' | '';
  medical_injury_description?: string;
  gender?: 'man' | 'woman' | 'other' | '';
  race?: 'white' | 'hispanic' | 'black' | 'native' | 'asian' | 'other' | '';
  marital_status?: 'single' | 'married' | 'divorced' | 'widowed' | 'other' | '';
  education?: 'high_school' | 'bachelors' | 'masters' | 'doctorate' | 'other' | '';
  tdl_number?: string;
  occupation?: string;
  employer?: string;
  employment_length?: string;
  work_phone_number?: string;
  spouse_name?: string;
  spouse_occupation?: string;
  spouse_employer?: string;
  spouse_employment_length?: string;
  is_us_citizen?: string;
  has_been_in_criminal_case?: string;
  has_served_on_civil_jury?: string;
  has_served_on_criminal_jury?: string;
  number_of_children?: string;
}

const getStateValue = (givenState) => {
  if (!givenState) return 'TX';
  const stateObj = STATE_ABBREVIATIONS.find((state) => {
    return state.value.toLowerCase() === givenState.toLowerCase();
  });
  if (!stateObj) return 'TX';

  return stateObj.value.toUpperCase();
};

const EditJurorForm: React.FC<EditJurorFormProps> = ({
  juror,
  jurorNumber,
  onJurorUpdate,
  onNextJuror,
  onPreviousJuror,
  disableNextJuror = false,
  disablePreviousJuror = false,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [jurorState, setJurorState] = React.useState<JurorState>({
    first_name: juror?.first_name ?? '',
    middle_name: juror?.middle_name ?? '',
    last_name: juror?.last_name ?? '',
    children: juror?.children ?? 0,
    gender: juror?.gender ?? '',
    race: juror?.race ?? '',
    marital_status: juror?.marital_status ?? '',
    education: juror?.education ?? '',
    date_of_birth: juror && juror.date_of_birth ? new Date(juror.date_of_birth) : new Date(),
    tdl_number: juror?.tdl_number ?? '',
    mailing_address: juror?.mailing_address ?? '',
    city: juror?.city ?? '',
    county: juror?.county ?? '',
    state: getStateValue(juror?.state),
    phone_number: juror?.phone_number ?? '',
    has_sustained_medical_injury: juror?.has_sustained_medical_injury ?? false,
    medical_injury_description: juror?.medical_injury_description ?? '',
    occupation: juror?.occupation ?? '',
    employer: juror?.employer ?? '',
    employment_length: juror?.employment_length ?? '',
    work_phone_number: juror?.work_phone_number ?? '',
    spouse_name: juror?.spouse_name ?? '',
    spouse_occupation: juror?.spouse_occupation ?? '',
    spouse_employer: juror?.spouse_employer ?? '',
    spouse_employment_length: juror?.spouse_employment_length ?? '',
    is_us_citizen: juror?.is_us_citizen ?? false,
    has_been_in_criminal_case: juror?.has_been_in_criminal_case ?? false,
    has_served_on_civil_jury: juror?.has_served_on_civil_jury ?? false,
    has_served_on_criminal_jury: juror?.has_served_on_criminal_jury ?? false,
    number_of_children: juror?.number_of_children ?? '',
  });
  const [search, setSearch] = React.useState(juror?.last_name + ' ' + juror?.first_name);
  const [selected, setSelected] = React.useState<HcadData>(null);
  const { data: hcadData } = useSearchByNameHCAD(search);

  const hcad = React.useMemo(() => (hcadData ? hcadData.data : []), [hcadData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload: any = { ...jurorState };
    payload.date_of_birth = format(payload.date_of_birth, 'yyyy-MM-dd');

    let returnValue = onJurorUpdate(juror.juror_id, payload);

    if (returnValue instanceof Promise) {
      setLoading(true);
      returnValue = returnValue.then(() => {
        setLoading(false);
      });
    }

    return returnValue || Promise.resolve();
  };

  const handleCancel = (e) => {
    // onCancel();
  };

  const debouncedSearch = debounce((value: string) => {
    if (search === value || selected?.name === value) return;
    if (value.split(' ').length < 2) return;
    setSearch(value);
  }, 300);

  const handleFieldChange = (e) => {
    const newState = {
      ...jurorState,
      [e.target.name]: e.target.value,
    };
    setJurorState(newState);

    if (
      e.target.name === 'first_name' ||
      e.target.name === 'middle_name' ||
      e.target.name === 'last_name'
    ) {
      let text = newState.last_name + ' ' + newState.first_name;
      if (newState.first_name && newState.middle_name) {
        text += ' ' + newState.middle_name;
      }
      debouncedSearch(text);
    }
    let payload = { ...newState };
    for (let key in payload) {
      if (payload[key] === '') {
        // need to patch nulls instead of empty strings
        payload[key] = null;
      }
    }
    // TODO: Don't do this on change, instead add save button and next/prev juror buttons to parent cmp
    // onJurorUpdate(juror.juror_id, payload);
  };

  const handleSelectName = async (value: HcadData) => {
    setSelected(value);
    const { first_name, middle_name, last_name } = splitName(value.name);
    const zipData = await fetchCode(value.zip)();
    const zip = zipData[0] || null;
    const newState = {
      ...jurorState,
      first_name,
      middle_name,
      last_name,
      mailing_address: value.address,
      city: zip?.city,
      state: zip?.state_code,
    };
    setJurorState(newState);
  };

  const handleDOBFieldChange = (newDateStr) => {
    const newDate = new Date(newDateStr);
    setJurorState((jurorState) => ({ ...jurorState, date_of_birth: newDate }));
  };

  const handleBooleanFieldChange = (e, newValue) => {
    let _value = false;
    if (newValue === 'true') {
      _value = true;
    }
    setJurorState((jurorState) => ({
      ...jurorState,
      [e.target.name]: _value,
    }));
  };

  const handlePreviousClick = () => {
    onPreviousJuror();
  };

  const handleNextClick = () => {
    onNextJuror();
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit} onReset={handleCancel}>
      <div className={classes.formContainer}>
        <div className={classes.formItemHalf}>
          <Typography className={classes.heading} variant="h4" gutterBottom>
            Juror #{jurorNumber}
          </Typography>
        </div>
        <div className={classnames(classes.formItemHalf, classes.navigation)}>
          <Button
            color="primary"
            variant="contained"
            disabled={loading || disablePreviousJuror}
            onClick={handlePreviousClick}
          >
            Previous Juror
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={loading || disableNextJuror}
            onClick={handleNextClick}
          >
            Next Juror
          </Button>
        </div>
        <div className={classes.formItem}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="gender">Gender</InputLabel>
            <Select
              native
              variant="outlined"
              label="Gender"
              value={jurorState.gender}
              onChange={handleFieldChange}
              inputProps={{
                name: 'gender',
                id: 'gender',
              }}
              fullWidth
            >
              <option aria-label="None" value="" />
              <option value="man">Man</option>
              <option value="woman">Woman</option>
              <option value="other">Other</option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.formItem}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="race">Race</InputLabel>
            <Select
              native
              label="Race"
              value={jurorState.race}
              onChange={handleFieldChange}
              inputProps={{
                name: 'race',
                id: 'race',
              }}
              fullWidth
            >
              <option aria-label="None" value="" />
              {ETHNICITIES.map(({ label, value }) => (
                <option key={'ethnicity_' + value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.formItem}>
          <DatePicker
            value={jurorState.date_of_birth}
            onChange={handleDOBFieldChange}
            label="Date of Birth"
            inputVariant="outlined"
            autoOk
            format="MM/dd/yyyy"
          />
        </div>
        <div className={classnames(classes.formItem, classes.centered)}>
          {jurorState.date_of_birth ? <span>Age: {getAge(jurorState.date_of_birth)}</span> : null}
        </div>
        <div className={classes.formItem}>
          <Autocomplete
            id="hcad-search-field"
            freeSolo
            disableClearable
            inputValue={jurorState.first_name}
            onInputChange={(event, newInputValue: string, reason) => {
              if (reason === 'input') {
                handleFieldChange({ target: { name: 'first_name', value: newInputValue } });
              }
            }}
            onChange={(event, newValue: HcadData) => {
              handleSelectName(newValue);
            }}
            options={hcad}
            getOptionLabel={(option) => splitName(option.name).first_name}
            renderOption={(option) => (
              <React.Fragment>
                {splitName(option.name).first_name} ({option.address})
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="First Name"
                name="first_name"
                size="medium"
                fullWidth
              />
            )}
          />
        </div>
        <div className={classes.formItem}>
          <Autocomplete
            id="hcad-search-field"
            freeSolo
            disableClearable
            inputValue={jurorState.middle_name}
            onInputChange={(event, newInputValue: string, reason) => {
              if (reason === 'input') {
                handleFieldChange({ target: { name: 'middle_name', value: newInputValue } });
              }
            }}
            onChange={(event, newValue: HcadData) => {
              handleSelectName(newValue);
            }}
            options={hcad}
            getOptionLabel={(option) => splitName(option.name).middle_name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Middle Name"
                name="middle_name"
                size="medium"
                fullWidth
              />
            )}
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="Last Name"
            name="last_name"
            value={jurorState.last_name}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="TDL#"
            name="tdl_number"
            value={jurorState.tdl_number}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="race">Number of Children</InputLabel>
            <Select
              native
              label="Number of Children"
              value={jurorState.number_of_children}
              onChange={handleFieldChange}
              inputProps={{
                name: 'number_of_children',
                id: 'number_of_children',
              }}
              fullWidth
            >
              <option aria-label="None" value="" />
              {NUMBER_OF_CHILDREN.map(({ label, value }) => (
                <option key={'children_' + label} aria-label={label} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.formItem}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="marital_status">Marital Status</InputLabel>
            <Select
              native
              variant="outlined"
              label="Marital Status"
              value={jurorState.marital_status}
              onChange={handleFieldChange}
              inputProps={{
                name: 'marital_status',
                id: 'marital_status',
              }}
              fullWidth
            >
              <option aria-label="None" value="" />
              <option value="single">Single</option>
              <option value="married">Married</option>
              <option value="divorced">Divorced</option>
              <option value="widowed">Widowed</option>
              <option value="other">Other</option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.formItemHalf}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="education">Education</InputLabel>
            <Select
              native
              label="Education"
              value={jurorState.education}
              onChange={handleFieldChange}
              inputProps={{
                name: 'education',
                id: 'education',
              }}
              fullWidth
            >
              <option aria-label="None" value="" />
              {EDUCATION_LEVELS.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.formItemFull}>
          <TextField
            variant="outlined"
            label="Mailing Address"
            name="mailing_address"
            value={jurorState.mailing_address}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="City"
            name="city"
            value={jurorState.city}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="County"
            name="county"
            value={jurorState.county}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="race">State</InputLabel>
            <Select
              native
              label="State"
              value={jurorState.state}
              onChange={handleFieldChange}
              inputProps={{
                name: 'state',
                id: 'state',
              }}
              fullWidth
            >
              <option aria-label="None" value="" />
              {STATE_ABBREVIATIONS.map(({ label, value }) => (
                <option key={label} aria-label={label} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="Phone Number"
            name="phone_number"
            value={jurorState.phone_number}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="Work Phone"
            name="work_phone_number"
            value={jurorState.work_phone_number}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="Occupation"
            name="occupation"
            value={jurorState.occupation}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="Employer"
            name="employer"
            value={jurorState.employer}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="How long?"
            name="employment_length"
            value={jurorState.employment_length}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="Spouse Name"
            name="spouse_name"
            value={jurorState.spouse_name}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="Spouse Occupation"
            name="spouse_occupation"
            value={jurorState.spouse_occupation}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="Spouse Employer"
            name="spouse_employer"
            value={jurorState.spouse_employer}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItem}>
          <TextField
            variant="outlined"
            label="How long?"
            name="spouse_employment_length"
            value={jurorState.spouse_employment_length}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItemHalf}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Have you ever sustained any accidental bodily injury requiring medical attention?
            </FormLabel>
            <RadioGroup
              row
              aria-label="has_sustained_medical_injury"
              name="has_sustained_medical_injury"
              value={jurorState.has_sustained_medical_injury.toString()}
              onChange={handleBooleanFieldChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.formItemHalf}>
          <TextField
            variant="outlined"
            label="If yes, what type?"
            name="medical_injury_description"
            value={jurorState.medical_injury_description}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
          />
        </div>
        <div className={classes.formItemHalf}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Are you a U.S. Citizen?</FormLabel>
            <RadioGroup
              row
              aria-label="is_us_citizen"
              name="is_us_citizen"
              value={jurorState.is_us_citizen.toString()}
              onChange={handleBooleanFieldChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.formItemHalf}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Have you ever been accused, complainant or witness of a criminal case?
            </FormLabel>
            <RadioGroup
              row
              aria-label="has_been_in_criminal_case"
              name="has_been_in_criminal_case"
              value={jurorState.has_been_in_criminal_case.toString()}
              onChange={handleBooleanFieldChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.formItemHalf}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Have you ever served on a civil jury?</FormLabel>
            <RadioGroup
              row
              aria-label="has_served_on_civil_jury"
              name="has_served_on_civil_jury"
              value={jurorState.has_served_on_civil_jury.toString()}
              onChange={handleBooleanFieldChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.formItemHalf}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Have you ever served on a criminal jury?</FormLabel>
            <RadioGroup
              row
              aria-label="has_served_on_criminal_jury"
              name="has_served_on_criminal_jury"
              value={jurorState.has_served_on_criminal_jury.toString()}
              onChange={handleBooleanFieldChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className={classes.actions}>
        <Button variant="contained" color="primary" type="submit" disabled={loading}>
          {loading ? 'Saving...' : 'Save Juror'}
        </Button>
      </div>
    </form>
  );
};

export default EditJurorForm;
