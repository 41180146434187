import { QueryClient, useMutation, useQuery } from 'react-query';
import api from 'utils/api';
import { IJuror } from 'state/interfaces';

const queryClient = new QueryClient();

export const fetchJurors = (caseId: string) => async () => {
  const jurors: IJuror[] = await api
    .cases(caseId)
    .jurors()
    .get();
  return jurors;
};

export const createJuror = (caseId: string) => (payload: Partial<IJuror>): Promise<IJuror> =>
  api
    .cases(caseId)
    .jurors()
    .post(payload);

export const patchJuror = (caseId: string) => ({ juror_id, ...updates }): Promise<IJuror> =>
  api
    .cases(caseId)
    .jurors(juror_id)
    .patch(updates);

export const updateJurorsOrder = (caseId: string) => ({ newJurorsOrder }): Promise<IJuror[]> =>
  api
    .cases(caseId)
    .jurors()
    .reorder()
    .post(newJurorsOrder);

export function useGetAllJurorsByCaseId(caseId: string) {
  return useQuery({
    queryKey: ['jurors', caseId],
    queryFn: fetchJurors(caseId),
  });
}

export function useAddJuror(caseId: string) {
  return useMutation({
    mutationFn: createJuror(caseId),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['jurors', caseId] }),
  });
}

export function useUpdateJuror(caseId: string) {
  return useMutation({
    mutationFn: patchJuror(caseId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['jurors', caseId] });
    },
  });
}

export function useReorderJurors(caseId: string) {
  return useMutation({
    mutationFn: updateJurorsOrder(caseId),
    onSuccess: () => {
      queryClient.invalidateQueries(['jurors', caseId]);
    },
  });
}
