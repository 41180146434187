import React from 'react';
import { makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, InputBase } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/SearchSharp';
import ResponsiveDrawer, { DRAWER_WIDTH } from './ResponsiveDrawer';
import { LayoutContext } from './context';
import { invertedTheme } from '../../styles/theme';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      height: '100vh',
    },
    '#__next': {
      height: '100%',
    },
  },
  root: {
    display: 'flex',
    height: '100%',
  },
  appBar: {
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}));

interface LayoutProps {
  children?: React.ReactNode;
  onSearch?: Function;
}

function Layout(props: LayoutProps) {
  const classes = useStyles(props);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSearch = (e) => {
    props.onSearch(e.target.value)
  }

  return (
    <div className={classes.root}>
      <LayoutContext.Provider value={{ handleDrawerToggle }}>
        <ThemeProvider theme={invertedTheme}>
          <ResponsiveDrawer
            isMobileOpen={mobileOpen}
            onDrawerClose={handleDrawerToggle}
          />
        </ThemeProvider>
        <AppBar
          elevation={1}
          color="default"
          position="fixed"
          className={classes.appBar}
          variant="outlined"
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <SearchIcon />
            <InputBase
              className={classes.input}
              placeholder="Search your cases"
              inputProps={{ 'aria-label': 'search cases notes jurors' }}
              onChange={handleSearch}
            />
            <Typography variant="h6" noWrap></Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {props.children}
        </main>
      </LayoutContext.Provider>
    </div>
  );
}

export default Layout;
