export function splitName(name: string) {
  let first_name = '',
    middle_name = '',
    last_name = '';
  if (name) {
    const nameSplitted = name.split(' ');
    if (nameSplitted.length === 2) {
      last_name = nameSplitted[0];
      first_name = nameSplitted[1];
    } else if (nameSplitted.length === 3) {
      last_name = nameSplitted[0];
      first_name = nameSplitted[1];
      middle_name = nameSplitted[2];
    } else if (nameSplitted.length > 3) {
      last_name = nameSplitted[0] + ' ' + nameSplitted[1];
      first_name = nameSplitted[2];
      middle_name = nameSplitted[3];
    }
  }

  return { first_name, middle_name, last_name };
}
