import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import GREEN from '@material-ui/core/colors/green';
import { CheckCircle as ResponseIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  listItem: {
    transition: '80ms ease-in',
    willChange: 'outline',
    minHeight: 80,
  },
  active: {
    boxShadow: `0 0 2pt 2pt ${theme.palette.primary.light}`,
  },
  grow: {
    flexGrow: 1,
  },
  icon: {
    color: theme.palette.text.primary,
  },
  text: {
    minWidth: '25%',
  },
  primary: {
    fontWeight: 700,
  },
  number: {
    fontWeight: 700,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  hint: {
    color: theme.palette.text.hint,
  },
  green: {
    color: GREEN[500],
  },
  response: {
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    transform: `translateY(2px)`,
  },
  strikeIcon: {
    height: 24,
    width: 24,
    opacity: 0.5,
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.25),
    fontWeight: 'bold',
    color: theme.palette.text.primary
  },
  strike: {
    backgroundColor: `rgba(0, 0, 0, 0.1)`
  }
}));

const StrikeIcon = () => {
  const classes = useStyles();
  return <span className={classes.strikeIcon}>C</span>
}

interface JurorListItemProps {
  active?: boolean;
  response?: boolean;
  strike?: boolean;
  juror_number: number;
  juror_id: string;
  first_name: string;
  last_name: string;
  onSelect: Function;
}

const JurorListItem: React.FC<JurorListItemProps> = ({
  active = false,
  response = false,
  strike = false,
  juror_id,
  juror_number,
  first_name,
  last_name,
  children,
  onSelect = () => {},
}) => {
  const classes = useStyles();

  const handleSelect = () => {
    onSelect(juror_number);
  };

  return (
    <ListItem
      key={juror_id}
      divider
      className={classnames(classes.listItem, active && classes.active, strike && classes.strike)}
      button
      onClick={handleSelect}
    >
      <ListItemIcon className={classes.icon}>
        <Typography
          className={classnames(classes.grow, classes.number, !response && classes.hint)}
          variant="h5"
        >
          {juror_number}
          {strike && <StrikeIcon />}
          {response && <ResponseIcon className={classnames(classes.green, classes.response)} />}
        </Typography>
      </ListItemIcon>
      <ListItemText
        className={classes.text}
        classes={{
          primary: classes.primary,
        }}
        primary={`${last_name}, ${first_name}`}
      />
      {children}
    </ListItem>
  );
};

export default JurorListItem;
