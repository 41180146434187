import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      height: '100vh',
    },
    '#root': {
      height: '100%',
    },
  }
}));

interface LayoutProps {
  children?: React.ReactNode;
}

function Layout(props: LayoutProps) {
  useStyles(props);

  return (
    <Container component="main" maxWidth="xs">
      {props.children}
    </Container>
  );
}

export default Layout;
