import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Popover,
  TextField,
  Select,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Chip,
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import {
  Add as AddIcon,
  ArrowDropDown as AscendingIcon,
  ArrowDropUp as DescendingIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { ResponsesByQuestion, ResponsesByJuror } from 'components';
import { differenceInYears } from 'date-fns';
import { useGetAllJurorsByCaseId } from 'hooks/queries/useJurorQueries';
import { useGetQuestionsByCaseId } from 'hooks/queries/useQuestionsQueries';
import { useGetAllResponses } from 'hooks/queries/useResponsesQueries';
import { useGetAllStrikes } from 'hooks/queries/useStrikeQueries';
import { responsesByJurorArr, sortJurors, strikesByJuror } from 'state/reducers';

const useStyles = makeStyles((theme) => ({
  viewSelection: {
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    background: theme.palette.background.default,
    zIndex: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  responsesContainer: {
    position: 'relative',
    height: '100%',
    overflowY: 'scroll',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(12),
  },
  sortAndFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  filterForm: {
    width: 300,
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  chipContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
}));

const SortAndFilter = ({
  activeFilters,
  onFilterClear,
  onFilterAdd,
  onAllFiltersClear,
  filterFields,
  sortDirection,
  onSortClick,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [field, setField] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');

  const handleFieldChange = (e) => {
    setField(e.target.value);
  };

  const handleFilterValueChange = (e) => {
    setFilterValue(e.target.value);
  };

  const handleFilterAddClick = (e) => {
    setAnchorEl(e.currentTarget);
    setField('');
    setFilterValue('');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();

    onFilterAdd({
      [field]: filterValue,
    });

    setField('');
    setFilterValue('');
    handleClose();
  };

  const isPopoverOpen = Boolean(anchorEl);
  const popoverId = isPopoverOpen ? 'filter-popover' : undefined;

  return (
    <div className={classes.sortAndFilter}>
      <div className={classes.grow}>
        {/* Filters */}
        <Button onClick={handleFilterAddClick} size="small" variant="outlined">
          <AddIcon className={classes.addIcon} />
          Add Filter
        </Button>
        <Popover
          id={popoverId}
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <CardContent>
            <form onSubmit={handleFilterSubmit} className={classes.filterForm}>
              <Typography gutterBottom variant="body1">
                Choose a field and value to filter by.
              </Typography>
              <FormControl required fullWidth variant="outlined">
                <InputLabel id="filter-field-label">Field</InputLabel>
                <Select
                  labelId="filter-field-label"
                  id="filter-field"
                  value={field}
                  onChange={handleFieldChange}
                  label="Field"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {filterFields.map(({ label, value }) => (
                    <MenuItem key={'filter_' + value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                required
                variant="outlined"
                label="Contains"
                disabled={!Boolean(field)}
                value={filterValue}
                onChange={handleFilterValueChange}
              />
              <Button
                fullWidth
                disabled={!Boolean(field)}
                color="primary"
                variant="contained"
                type="submit"
              >
                Save Filter
              </Button>
            </form>
          </CardContent>
        </Popover>
      </div>
      {Boolean(activeFilters.length) && (
        <div>
          <Button variant="outlined" size="small" onClick={onAllFiltersClear}>
            <ClearIcon className={classes.addIcon} />
            Clear Filters
          </Button>
        </div>
      )}
      {false && (
        <div>
          <Button variant="outlined" size="small" onClick={onSortClick}>
            {sortDirection === 'asc' && (
              <React.Fragment>
                <AscendingIcon className={classes.addIcon} />
                Ascending
              </React.Fragment>
            )}
            {sortDirection === 'desc' && (
              <React.Fragment>
                <DescendingIcon className={classes.addIcon} />
                Descending
              </React.Fragment>
            )}
          </Button>
        </div>
      )}
      <div className={classes.chipContainer}>
        {Object.entries(activeFilters).map(([key, value]) => {
          return (
            <Chip
              key={key + value}
              onDelete={() => onFilterClear(key)}
              label={`${key} contains ${value}`}
            ></Chip>
          );
        })}
      </div>
    </div>
  );
};

interface ResponsesPanelProps {
  focusedJurorId?: string;
  onJurorClick?: Function;
}

const jurorFilterFields = [
  {
    value: 'first_name',
    label: 'First Name',
  },
  {
    value: 'last_name',
    label: 'Last Name',
  },
  {
    value: 'gender',
    label: 'Gender',
  },
  {
    value: 'race',
    label: 'Ethnicity',
  },
  {
    value: 'age',
    label: 'Age',
  },
  {
    value: 'marital_status',
    label: 'Marital Status',
  },
  {
    value: 'education',
    label: 'Education',
  },
  {
    value: 'number_of_children',
    label: 'No. of Children',
  },
  {
    value: 'occupation',
    label: 'Occupation',
  },
  {
    value: 'employer',
    label: 'Employer',
  },
  {
    value: 'is_us_citizen',
    label: 'U.S. Citizen',
  },
];

const questionFilterFields = [];

const ResponsesPanel: React.FC<ResponsesPanelProps> = ({ focusedJurorId, onJurorClick }) => {
  const classes = useStyles();
  const { caseId } = useParams();
  const [view, setView] = React.useState<'juror' | 'question'>('juror');
  const [jurorFilters, setJurorFilters] = React.useState({});
  const [questionFilters, setQuestionFilters] = React.useState({});
  const [sortDirection, setSortDirection] = React.useState('asc');
  const { data: jurorsData, isLoading: isLoadingJurors } = useGetAllJurorsByCaseId(caseId);
  const { data: questionsData, isLoading: isLoadingQuestions } = useGetQuestionsByCaseId(caseId);
  const { data: responsesData } = useGetAllResponses(caseId);
  const { data: strikesData } = useGetAllStrikes(caseId);

  React.useEffect(() => {
    if (!focusedJurorId) return;
    // Ensure that view is set to 'juror'
    setView(() => 'juror');
  }, [focusedJurorId]);

  const handleViewChange = (e, newValue) => {
    if (newValue) {
      setView(newValue);
      setSortDirection('asc');
    }
  };

  const handleAddFilter = (filter) => {
    const setter = view === 'juror' ? setJurorFilters : setQuestionFilters;
    setter((prevState) => ({ ...prevState, ...filter }));
  };

  const handleClearFilter = (filterField: string) => {
    const setter = view === 'juror' ? setJurorFilters : setQuestionFilters;
    setter((prevState: { [key: string]: any }) => {
      const { [filterField]: unwanted, ...restState } = prevState;
      return restState;
    });
  };

  const handleClearAllFilters = () => {
    const setter = view === 'juror' ? setJurorFilters : setQuestionFilters;
    setter({});
  };

  const toggleSortDirection = () => {
    setSortDirection((direction) => (direction === 'asc' ? 'desc' : 'asc'));
  };

  const loading = isLoadingQuestions || isLoadingJurors;

  const filterFields = view === 'juror' ? jurorFilterFields : questionFilterFields;
  const filters = view === 'juror' ? jurorFilters : questionFilters;

  const jurorsMap = useMemo(() => (jurorsData ? jurorsData : []), [jurorsData]);
  const questions = useMemo(() => (questionsData ? questionsData : []), [questionsData]);
  const responses = useMemo(() => (responsesData ? responsesData : []), [responsesData]);
  const responsesJurors = useMemo(
    () => (responsesData ? responsesByJurorArr(responsesData) : []),
    [responsesData],
  );
  const strikesJuror = useMemo(
    () => (strikesData ? strikesByJuror(strikesData) : []),
    [strikesData],
  );

  const filteredJurors = React.useMemo(() => {
    if (!jurorsData) return [];
    const jurorsArr = sortJurors(jurorsData);

    return jurorsArr.filter((juror) => {
      for (let [field, value] of Object.entries(jurorFilters)) {
        // Handle "Age" differently, because we need to compare dates
        if (field.toLowerCase() === 'age') {
          const trimmed = (value as string).trim();
          let match = /[0-9]+/.exec(trimmed);
          if (!match) continue;

          let targetAgeDiff = Number(match[0]);
          const now = Date.now();

          const difference = differenceInYears(now, new Date(juror.date_of_birth));

          if (trimmed.startsWith('>=')) {
            if (!(difference >= targetAgeDiff)) return false;
          } else if (trimmed.startsWith('<=')) {
            if (!(difference <= targetAgeDiff)) return false;
          } else if (trimmed.startsWith('>')) {
            if (!(difference > targetAgeDiff)) return false;
          } else if (trimmed.startsWith('<')) {
            if (!(difference < targetAgeDiff)) return false;
          } else if (trimmed.startsWith('=')) {
            if (!(difference === targetAgeDiff)) return false;
          }
        }

        const jurorValue = juror[field];
        if (!jurorValue) return false;

        const jurorValueStr = jurorValue.toString().toLowerCase();

        if (typeof jurorValue === 'boolean') {
          if ((value as string).toLowerCase() === 'yes') {
            value = 'true';
          } else if ((value as string).toLowerCase() === 'no') {
            value = 'false';
          }
        }

        if (!jurorValueStr.includes((value as string).toLowerCase())) return false;
      }
      return true;
    });
  }, [jurorsData, jurorFilters]);

  // @ts-ignore
  // const sortedAndFilteredJurors = React.memo(() => {
  //   if (!filteredJurors) return []
  //   const sorted = filteredJurors.sort((a, b) => {
  //     if (sortDirection === 'asc') {
  //       return a - b;
  //     } else if (sortDirection === 'desc') {
  //       return b - a;
  //     }
  //     return a - b
  //   });
  //   return sorted;
  // }, [JSON.stringify(filteredJurors || [])]);

  return (
    <>
      <div className={classes.viewSelection}>
        <ToggleButtonGroup exclusive value={view} onChange={handleViewChange}>
          <ToggleButton value="juror">By Juror</ToggleButton>
          <ToggleButton value="question">By Question</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.responsesContainer}>
        {view === 'juror' && (
          <SortAndFilter
            onFilterAdd={handleAddFilter}
            onFilterClear={handleClearFilter}
            onAllFiltersClear={handleClearAllFilters}
            activeFilters={filters}
            filterFields={filterFields}
            sortDirection={sortDirection}
            onSortClick={toggleSortDirection}
          />
        )}
        {!loading && view === 'juror' && (
          <ResponsesByJuror
            jurors={filteredJurors}
            focusedJurorId={focusedJurorId}
            responses={responsesJurors}
            strikes={strikesJuror}
            questions={questions}
          />
        )}
        {!loading && view === 'question' && (
          <ResponsesByQuestion
            jurors={jurorsMap}
            questions={questions}
            responses={responses}
            onJurorClick={onJurorClick}
          />
        )}
      </div>
    </>
  );
};

export default ResponsesPanel;
