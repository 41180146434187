import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { List } from '@material-ui/core';
import PURPLE from '@material-ui/core/colors/purple';
import { useTheme } from '@material-ui/core/styles';
import { JurorPageListItem } from 'components';
import { IJuror } from 'state/interfaces';

const createGetItemStyle = theme => (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background colour if dragging
  background: isDragging ? PURPLE[100] : theme.palette.background.paper,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const createGetListStyle = theme => isDraggingOver => ({
  background: theme.palette.background.default,
});

// a little function to help us with reordering the result
const reorder = (list: IJuror[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface Props {
  jurors: IJuror[];
  jurorState: IJuror[];
  onOrderUpdate: Function;
}

const DraggableJurorsList = ({ jurors = [], jurorState, onOrderUpdate }: Props) => {
  const theme = useTheme();

  const getItemStyle = createGetItemStyle(theme);
  const getListStyle = createGetListStyle(theme);

  const handleDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const list = jurorState || jurors;

    const items = reorder(list, result.source.index, result.destination.index);

    onOrderUpdate(items);
  };

  return (
    <List>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {(jurorState || jurors).map((juror, index) => (
                <Draggable key={juror.juror_id} draggableId={juror.juror_id} index={index}>
                  {(provided, snapshot) => (
                    <JurorPageListItem
                      dragIcon
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      firstName={juror.first_name}
                      lastName={juror.last_name}
                      jurorNumber={index + 1}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </List>
  );
};

export default DraggableJurorsList;
