import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { AskingPartyBadger, ResponseDetail } from 'components';
import { IJuror, IQuestion, IQuestionResponseWithJuror } from 'state/interfaces';

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
}));

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    borderLeft: (props: { askingparty: AskingParty }) =>
      `4px solid ${theme.palette[props.askingparty].main}`,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
    alignItems: 'center',
  },
  expanded: {},
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiExpansionPanelDetails);

interface ResponsesByQuestionProps {
  questions: IQuestion[];
  responses: IQuestionResponseWithJuror[];
  jurors: IJuror[];
  onJurorClick?: Function;
}

const ResponsesByQuestion: React.FC<ResponsesByQuestionProps> = ({
  questions,
  responses,
  jurors,
  onJurorClick,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(() =>
    questions.reduce((acc, question, i) => {
      acc[question.id] = false;
      return acc;
    }, {}),
  );

  const createChangeHandler = (questionId) => (e, newPanel) => {
    setExpanded({ ...expanded, [questionId]: newPanel });
  };

  return (
    <>
      {questions.map((question, i) => {
        const questionResponses = responses.filter(
          (response) => response.question_id === question.id,
        );
        return (
          <ExpansionPanel
            key={question.id}
            square
            expanded={expanded[question.id]}
            onChange={createChangeHandler(question.id)}
          >
            <ExpansionPanelSummary
              askingparty={question.asking_party}
              aria-controls={`${question.id}-controls`}
              id={`${question.id}-header`}
            >
              <AskingPartyBadger party={question.asking_party} />
              <Typography className={classes.marginLeft}>
                <strong>{`Q${i + 1}: `}</strong>
                {question.question}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {questionResponses.map((response) => {
                const juror = jurors.find((juror) => juror.juror_number === response.juror_number);
                return (
                  <ResponseDetail
                    key={response.response_id}
                    variant="juror"
                    juror={juror}
                    response={response.response}
                    onClick={onJurorClick}
                    question={response.question}
                    type={response.question_type}
                    options={response.question_options}
                    responseId={response.response_id}
                  />
                );
              })}
              {questionResponses.length === 0 && (
                <Typography variant="body2" color="textSecondary">
                  No responses recorded for this question.
                </Typography>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </>
  );
};

export default ResponsesByQuestion;
