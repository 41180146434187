import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, IconButton, Typography } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  choiceContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
    '&:not(:first-child)': {
      marginTop: theme.spacing(3),
    },
  },
  prefix: {
    width: 70,
  },
  grow: {
    flexGrow: 1,
  },
}));

type MultiChoice = {
  prefix: string;
  description: string;
};

interface MultiQuestionFormProps {
  options?: any;
  onUpdate?: Function;
}

const initialState = [{ prefix: 'A', description: '' }];

const MultiQuestionForm = React.forwardRef(
  ({ options, onUpdate = () => {} }: MultiQuestionFormProps, ref) => {
    const classes = useStyles();
    const [choices, setChoices] = React.useState<MultiChoice[]>(options?.choices ?? initialState);
    const [notes, setNotes] = React.useState<string>(options?.notes ?? '');

    const handleAddChoice = () => {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const prefix = alphabet[choices.length];
      const newChoices = [...choices, { prefix, description: '' }];
      setChoices(newChoices);
      onUpdate({ choices: newChoices, notes });
    };

    const createChoiceChangeHandler = index => e => {
      const _choices = [...choices];
      const choice = _choices[index];
      choice[e.target.name] = e.target.value;
      _choices[index] = choice;
      setChoices(_choices);
      onUpdate({ choices: _choices, notes });
    };

    const createChoiceRemoveHandler = index => () => {
      const _choices = [...choices];
      _choices.splice(index, 1);
      setChoices(_choices);
      onUpdate({ choices: _choices, notes });
    };

    const handleNotesChange = e => {
      setNotes(e.target.value);
      onUpdate({ choices, notes: e.target.value });
    };

    React.useImperativeHandle(ref, () => ({
      getQuestionOptions: () => ({ choices, notes }),
    }));

    return (
      <>
        {choices.length ? (
          choices.map(({ prefix, description }, i) => (
            <div key={i} className={classes.choiceContainer}>
              <span>
                <IconButton onClick={createChoiceRemoveHandler(i)}>
                  <CancelIcon />
                </IconButton>
              </span>
              <TextField
                value={prefix}
                onChange={createChoiceChangeHandler(i)}
                name="prefix"
                variant="outlined"
                className={classes.prefix}
                label="Prefix"
                autoFocus
              />
              <TextField
                value={description}
                onChange={createChoiceChangeHandler(i)}
                name="description"
                variant="outlined"
                className={classes.grow}
                label="Description"
              />
            </div>
          ))
        ) : (
          <div className={classes.choiceContainer}>
            <Typography variant="body1">
              No choices. Click "Add choice" to add at least one choice.
            </Typography>
          </div>
        )}
        <div className={classes.choiceContainer}>
          <Button color="primary" variant="outlined" onClick={handleAddChoice}>
            Add choice
          </Button>
        </div>
        <div className={classes.choiceContainer}>
          <TextField
            label="Notes"
            fullWidth
            variant="outlined"
            multiline
            value={notes}
            onChange={handleNotesChange}
          />
        </div>
      </>
    );
  },
);

export default MultiQuestionForm;
