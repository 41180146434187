import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NewQuestionForm } from 'components';

const noop = () => {};

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 600,
    width: 600,
    padding: theme.spacing(2),
    maxHeight: '90vh',
    overflowY: 'auto',
  },
}));

const QuestionAddOverlay: React.FC<{
  onSubmit?: Function;
  onCancel?: Function;
}> = ({ onSubmit = noop, onCancel = noop }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NewQuestionForm onSubmit={onSubmit} onCancel={onCancel} />
    </div>
  );
};

export default QuestionAddOverlay;
