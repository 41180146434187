import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { NewQuestionForm, EditQuestionForm, QuestionsList, Spinner, EmptyState } from 'components';
import {
  useAddQuestion,
  useGetQuestionsByCaseId,
  useUpdateQuestion,
} from 'hooks/queries/useQuestionsQueries';
import { useAtom } from 'jotai';
import { activeQuestion } from 'store';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    flexGrow: 1,
    display: 'flex',
  },
  addQuestion: {
    position: 'sticky',
    top: 0,
    width: '100%',
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    zIndex: 99,
  },
  sidebar: {
    flexBasis: 550,
    height: '100%',
    overflowY: 'auto',
    background: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  content: {
    width: '100%',
    maxWidth: '70%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflowY: 'auto',
  },
  grow: {
    flexGrow: 1,
  },
}));

const Questions = () => {
  const classes = useStyles();
  const addButtonRef = React.useRef(null);
  const { caseId } = useParams();
  const [currentQuestion, setCurrentQuestion] = useAtom(activeQuestion);
  const [isNewQuestion, setNewQuestion] = React.useState(false);

  const { isLoading, refetch } = useGetQuestionsByCaseId(caseId);

  const addQuestion = useAddQuestion(caseId);
  const handleNewQuestionSubmit = payload => {
    addQuestion.mutateAsync(payload).then(() => {
      setNewQuestion(false);
      addButtonRef.current.focus();
      refetch();
    });
  };

  const handleNewQuestionCancel = () => {
    setNewQuestion(false);
  };

  const handleAddQuestion = () => {
    setNewQuestion(true);
    //@ts-ignore
    setCurrentQuestion(null);
  };

  const updateQuestion = useUpdateQuestion(caseId);
  const handleEditQuestion = (questionId: string, payload) =>
    updateQuestion.mutateAsync({ questionId, payload }).then(() => refetch());

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={classes.root}>
      <aside className={classes.sidebar}>
        <div className={classes.addQuestion}>
          <Button variant="outlined" ref={addButtonRef} onClick={handleAddQuestion}>
            Add a new question
          </Button>
        </div>
        <QuestionsList onSelectedQuestion={handleNewQuestionCancel} />
      </aside>
      <main className={classnames(classes.content)}>
        {isNewQuestion ? (
          <NewQuestionForm onSubmit={handleNewQuestionSubmit} onCancel={handleNewQuestionCancel} />
        ) : currentQuestion ? (
          <EditQuestionForm
            key={currentQuestion.id}
            question={currentQuestion}
            onQuestionUpdate={handleEditQuestion}
            onCancel={handleNewQuestionCancel}
          />
        ) : (
          <EmptyState>Click "Add a new question" or edit an existing question</EmptyState>
        )}
      </main>
    </div>
  );
};

export default Questions;
