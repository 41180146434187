import { useQuery } from 'react-query';
import api from 'utils/api';
import { ICase, ICaseResponse } from 'state/interfaces';

export const fetchCase = (caseId: string) => (): Promise<ICase> => {
  return api.cases(caseId).get();
};

const fetchCases = (): Promise<ICaseResponse[]> => {
  return api.cases().get();
};

export function useGetCase(id: string) {
  return useQuery({
    queryKey: ['case', id],
    queryFn: fetchCase(id),
  });
}

export function useGetAllCases() {
  return useQuery({
    queryKey: ['cases'],
    queryFn: fetchCases,
  });
}
