import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import { CenterLayout } from 'components/layouts';
import { Anchor } from 'components';
import { useDocumentTitle } from 'utils/document';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    margin: '0 auto',
  },
}));

const NotFound = () => {
  useDocumentTitle('Page not found | Second Chair');
  const classes = useStyles();
  return (
    <CenterLayout>
      <Box className={classes.root}>
        <Typography variant="h4">Page Not Found</Typography>
        <Anchor href="/">Return Home</Anchor>
      </Box>
    </CenterLayout>
  );
};

export default NotFound;
