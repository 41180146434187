import React from 'react';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import NavMenu from '../NavMenu';

export const DRAWER_WIDTH = 300;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    background: theme.palette.background.default,
    width: DRAWER_WIDTH
  }
}));

interface ResponsiveDrawerProps {
  isMobileOpen: boolean;
  onDrawerClose: () => void;
}

function ResponsiveDrawer(props: ResponsiveDrawerProps) {
  const { isMobileOpen } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden lgUp implementation="css">
        <Drawer
          color="default"
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={isMobileOpen}
          onClose={props.onDrawerClose}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <NavMenu />
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          <NavMenu />
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default ResponsiveDrawer;
