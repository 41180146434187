import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { IJuror } from 'state/interfaces';

interface JurorsListProps {
  jurors: IJuror[];
  activeJurorNumber?: number;
  onSelect?: (id: number) => void;
}

const JurorsList: React.FC<JurorsListProps> = ({
  jurors = [],
  activeJurorNumber = null,
  onSelect = () => {},
}) => {
  return (
    <List dense>
      {jurors &&
        jurors.map(juror => {
          let name = '';
          if (!juror.first_name && !juror.last_name) {
            name = '(unnamed juror)';
          } else {
            name += juror.first_name ? `${juror.first_name} ` : '';
            name += juror.last_name ?? '';
          }
          return (
            <ListItem
              divider
              key={juror.juror_id ? juror.juror_id : juror.juror_number + 'menu-item'}
              button
              selected={activeJurorNumber === juror.juror_number}
              onClick={() => onSelect(juror.juror_number)}
            >
              <ListItemText>{`#${juror.juror_number}: ${name}`}</ListItemText>
            </ListItem>
          );
        })}
    </List>
  );
};

export default JurorsList;
