import React, { useMemo } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Chip, Button, Typography, CircularProgress } from '@material-ui/core';
import {
  CheckCircleOutlined as ConfirmOutlined,
  CheckCircle as ConfirmFilled,
  Cancel as DenyFilled,
  CancelOutlined as DenyOutlined,
  Report as StrikeFilled,
  ReportOutlined as StrikeOutlined,
} from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import {
  useGetConfirmations,
  useUpdateConfirmationState,
} from 'hooks/queries/useConfirmationQueries';
import { useGetAllJurorsByCaseId } from 'hooks/queries/useJurorQueries';
import { useGetAllStrikes, useAddStrike, useRemoveStrike } from 'hooks/queries/useStrikeQueries';
import { confirmationByJuror, sortJurors, strikesByPartyMap } from 'state/reducers';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  confControlContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 100px 1fr',
    width: 200,
  },
  confControlLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  headerItem: {
    width: 100,
    fontWeight: 700,
    color: theme.palette.text.hint,
    textAlign: 'center',
  },
  chip: {
    width: 130,
    textAlign: 'left',
  },
  prosecution: {
    // @ts-ignore
    color: theme.palette.prosecution.main,
    // @ts-ignore
    borderColor: theme.palette.prosecution.main,
  },
  defense: {
    // @ts-ignore
    color: theme.palette.defense.main,
    // @ts-ignore
    borderColor: theme.palette.defense.main,
  },
  prosecutionBg: {
    // @ts-ignore
    background: theme.palette.prosecution.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      // @ts-ignore
      background: theme.palette.prosecution.main,
      // @ts-ignore
      borderColor: theme.palette.prosecution.main,
    },
  },
  defenseBg: {
    // @ts-ignore
    background: theme.palette.defense.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      // @ts-ignore
      background: theme.palette.defense.main,
      // @ts-ignore
      borderColor: theme.palette.defense.main,
    },
  },
  header: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    position: 'sticky',
    top: 0,
    background: theme.palette.background.default,
    zIndex: 1,
  },
  strikeIcon: {
    color: theme.palette.primary.contrastText,
  },
}));

const JurorStrikeControl: React.FC<{
  party?: 'prosecution' | 'defense';
  strike?: boolean;
  onClick?: any;
}> = ({ party = 'prosecution', strike = false, onClick = () => {} }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleClick = () => {
    setLoading(true);
    onClick(party).then(() => setLoading(false));
  };

  const StrikeIcon = strike ? StrikeFilled : StrikeOutlined;

  return (
    <Chip
      clickable
      onClick={handleClick}
      disabled={loading}
      color={party === 'prosecution' ? 'primary' : 'secondary'}
      className={classnames(classes.chip, strike && classes[party + 'Bg'])}
      variant={strike ? 'default' : 'outlined'}
      icon={loading ? <CircularProgress size={12} /> : <StrikeIcon />}
      label={strike ? 'Strike for cause' : 'No strikes'}
    />
  );
};

const JurorConfirmationControl: React.FC<{
  status?: 'confirm' | 'deny';
  number?: number;
  name?: string;
  onConfirmation?: (status: 'confirm' | 'deny' | null) => void;
}> = ({ status = null, number = 10, name = 'John Doe', onConfirmation = () => {} }) => {
  const classes = useStyles();

  const createConfirmationHandler = (newStatus) => () => {
    if (newStatus === status) {
      onConfirmation(null);
    } else {
      onConfirmation(newStatus);
    }
  };

  return (
    <div className={classes.confControlContainer}>
      <Button onClick={createConfirmationHandler('deny')}>
        {status === 'deny' && <DenyFilled />}
        {status !== 'deny' && <DenyOutlined />}
      </Button>
      <div className={classes.confControlLabel}>
        <Typography variant="h6">Juror #{number}</Typography>
      </div>
      <Button onClick={createConfirmationHandler('confirm')}>
        {status === 'confirm' && <ConfirmFilled />}
        {status !== 'confirm' && <ConfirmOutlined />}
      </Button>
    </div>
  );
};

const HeaderItem: React.FC<{
  text?: string;
  color?: 'prosecution' | 'defense';
}> = ({ text, color }) => {
  const classes = useStyles();
  return (
    <Typography variant="body1" className={classnames(classes.headerItem, classes[color])}>
      {text}
    </Typography>
  );
};

const StrikesConfirmation: React.FC<{}> = () => {
  const classes = useStyles();
  const { caseId } = useParams();
  const { data: confirmationData, refetch: refetchConfirmations } = useGetConfirmations(caseId);
  const { data: jurorsData } = useGetAllJurorsByCaseId(caseId);
  const { data: strikesData, refetch: refetchStrikes } = useGetAllStrikes(caseId);

  const confirmationJuror = useMemo(
    () => (confirmationData ? confirmationByJuror(confirmationData) : []),
    [confirmationData],
  );
  const jurors = useMemo(() => (jurorsData ? sortJurors(jurorsData) : []), [jurorsData]);
  const strikesParties = useMemo(
    () => (strikesData ? strikesByPartyMap(strikesData) : []),
    [strikesData],
  );

  const updateConfirmation = useUpdateConfirmationState(caseId);
  const createJurorConfirmationHandler = (jurorId: string) => (state: string) => {
    updateConfirmation.mutateAsync({ jurorId, state }).then(() => refetchConfirmations());
  };

  const addStrike = useAddStrike(caseId);
  const removeStrike = useRemoveStrike(caseId);
  const createStrikeClickHandler = (jurorId: string, strikeId: string | null) => (party) => {
    if (!strikeId) {
      return addStrike
        .mutateAsync({
          juror_id: jurorId,
          striking_party: party,
        })
        .then(() => refetchStrikes());
    } else {
      return removeStrike.mutateAsync(strikeId).then(() => refetchStrikes());
    }
  };

  return (
    <List>
      <ListItem divider className={classnames(classes.listItem, classes.header)}>
        <HeaderItem color="prosecution" text="Prosecution" />
        <HeaderItem text="Deny" />
        <HeaderItem text="Confirm" />
        <HeaderItem color="defense" text="Defense" />
      </ListItem>
      {jurors.map((juror) => {
        const defenseStrike = strikesParties?.['defense']?.[juror.juror_id];
        const prosecutionStrike = strikesParties?.['prosecution']?.[juror.juror_id];
        const isDefenseStrike = Boolean(defenseStrike);
        const isProsecutionStrike = Boolean(prosecutionStrike);

        return (
          <ListItem key={juror.juror_id} divider className={classnames(classes.listItem)}>
            <JurorStrikeControl
              party="prosecution"
              strike={isProsecutionStrike}
              onClick={createStrikeClickHandler(
                juror.juror_id,
                isProsecutionStrike ? prosecutionStrike.strike_id : null,
              )}
            />
            <JurorConfirmationControl
              number={juror.juror_number}
              status={confirmationJuror?.[juror.juror_id]?.state}
              onConfirmation={createJurorConfirmationHandler(juror.juror_id)}
            />
            <JurorStrikeControl
              party="defense"
              strike={isDefenseStrike}
              onClick={createStrikeClickHandler(
                juror.juror_id,
                isDefenseStrike ? defenseStrike.strike_id : null,
              )}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default StrikesConfirmation;
