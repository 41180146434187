import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, TextField } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { FreetextQuestionForm, MultiQuestionForm, ScaleQuestionForm } from 'components';
import EmptyState from 'components/UI/EmptyState';

const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  prefix: {
    width: 70,
  },
  grow: {
    flexGrow: 1,
  },
  actions: {
    textAlign: 'right',
    marginTop: theme.spacing(3),
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  askingParty: {
    minWidth: 150,
  },
}));

const questionTypeSubforms = {
  multi: MultiQuestionForm,
  freetext: FreetextQuestionForm,
  scale: ScaleQuestionForm,
};

interface EditQuestionFormProps {
  question: any;
  onQuestionUpdate?: (question_id?: string, updates?: QuestionState) => any;
  onCancel?: Function;
}

interface QuestionState {
  question?: string;
  type?: 'multi' | 'freetext' | 'scale';
}

const EditQuestionForm: React.FC<EditQuestionFormProps> = ({
  question,
  onQuestionUpdate,
  onCancel,
}) => {
  const classes = useStyles();
  const subformRef = React.useRef(null);
  const [questionState, setQuestionState] = React.useState<QuestionState>({
    question: question?.question ?? '',
    type: question?.type ?? null,
  });
  const [isSubmitting, setSubmitting] = React.useState(false);

  const handleSubmit: React.FormEventHandler = async e => {
    e.preventDefault();
    const payload = {
      ...questionState,
      options: subformRef?.current?.getQuestionOptions() || {},
    };
    setSubmitting(true);
    const updated = await onQuestionUpdate(question.id, payload);
    if (updated) {
      setSubmitting(false);
    }
    // onSubmit(payload);
  };

  const handleCancel: React.FormEventHandler = e => {
    e.preventDefault();
    // onCancel();
  };

  const handleFieldChange = async e => {
    const newState = {
      ...questionState,
      [e.target.name]: e.target.value,
    };
    setQuestionState(newState);
    let payload = { ...newState };
    for (let key in payload) {
      if (payload[key] === '') {
        // need to patch nulls instead of empty strings
        payload[key] = null;
      }
    }
    onQuestionUpdate(question.id, payload);
  };

  // const handleTypeChange = (e, newValue) => {
  //   setQuestionState({
  //     ...questionState,
  //     type: newValue,
  //   });
  // };

  if (!question || !question.type) return <EmptyState>Select a question to edit</EmptyState>;

  const QuestionTypeComponent = questionTypeSubforms[question?.type];

  return (
    <form className={classes.form} onSubmit={handleSubmit} onReset={handleCancel}>
      <Typography className={classes.heading} variant="h6" gutterBottom>
        Edit Question
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Question"
            name="question"
            value={questionState.question}
            onChange={handleFieldChange}
            size="medium"
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item xs={8}>
          <ToggleButtonGroup
            exclusive
            value={questionState.type}
            // onChange={handleTypeChange}
            // onReset={handleCancel}
          >
            <ToggleButton disabled value="freetext">
              Free Text
            </ToggleButton>
            <ToggleButton disabled value="multi">
              Multiple Choice
            </ToggleButton>
            <ToggleButton disabled value="scale">
              Numeric Scale
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <QuestionTypeComponent options={question.options} ref={subformRef} />
        </Grid>
        <Grid className={classes.actions} item xs={12}>
          <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
            {isSubmitting ? 'Updating' : 'Update'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditQuestionForm;
