import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, TextField, Container, Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import api from 'utils/api';
import { SheetLayout } from 'components/layouts';

interface AddCaseFormState {
  case_number?: number;
  plaintiff?: string;
  defendant?: string;
  self_party?: 'prosecution' | 'defense' | 'judge';
}

const formReducer = (state, [key, value]) => {
  return { ...state, [key]: value };
};

const initialState: AddCaseFormState = {
  case_number: 0,
  plaintiff: '',
  defendant: '',
  self_party: 'prosecution',
};

const Add = () => {
  const [formState, dispatch] = React.useReducer(formReducer, initialState);
  const setField = (...args: [string, {}]) => dispatch(args);
  const history = useHistory();

  const handleChange = (event) => {
    let value = event.target.value;
    if (event.target.type === 'number') {
      value = Number(value);
    }
    setField(event.target.name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    api
      .cases()
      .post(formState)
      .then((res) => {
        history.push('/cases');
      });
  };

  return (
    <SheetLayout title="Add a new case">
      <Container maxWidth="sm" fixed>
        <Box
          mt={{
            xs: 4,
            sm: 4,
            md: 8,
          }}
          textAlign="center"
        >
          <Typography variant="h4" component="h2">
            Create a new case
          </Typography>
          <Typography variant="subtitle1" component="h6">
            Fill out the information below to get started
          </Typography>
        </Box>
        <Box component="form" onSubmit={handleSubmit} mt={1}>
          <TextField
            id="case_number"
            label="Case Number"
            name="case_number"
            type="number"
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
          />
          <TextField
            id="juror_count"
            label="Juror Pool Size"
            name="juror_count"
            type="number"
            onChange={handleChange}
            inputProps={{
              type: 'number',
              min: '1',
              max: '90',
              step: '1',
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            required
          />
          <TextField
            id="jury_size"
            label="Jury Size"
            name="jury_size"
            type="number"
            onChange={handleChange}
            inputProps={{
              type: 'number',
              min: '1',
              max: '90',
              step: '1',
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            required
          />
          <TextField
            id="description"
            label="Description"
            name="description"
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            id="plaintiff"
            label="Plaintiff"
            name="plaintiff"
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
          />
          <TextField
            id="defendant"
            label="Defendant"
            name="defendant"
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
          />
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel id="self_party_label">My Party</InputLabel>
            <Select
              labelId="self_party_label"
              id="self_party"
              name="self_party"
              value={formState.self_party}
              onChange={handleChange}
              label="My Party"
              autoWidth
            >
              <MenuItem value="prosecution">Prosecution</MenuItem>
              <MenuItem value="defense">Defense</MenuItem>
              <MenuItem value="judge">Judge</MenuItem>
            </Select>
          </FormControl>
          <Box mt={1}>
            <Button size="large" type="submit" fullWidth variant="contained" color="primary">
              Create
            </Button>
          </Box>
        </Box>
      </Container>
    </SheetLayout>
  );
};

export default Add;
