import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/FolderSharp';
import TuneIcon from '@material-ui/icons/TuneSharp';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}));

interface NavMenuPage {
  label: string;
  href: string;
  icon?: React.ElementType;
}

const contentPages: NavMenuPage[] = [
  {
    label: 'Cases',
    href: '/cases',
    icon: FolderIcon,
  },
];

const otherPages: NavMenuPage[] = [
  {
    label: 'Log Out',
    href: '/logout',
    icon: TuneIcon,
  },
];

interface NavMenuLinkProps {
  to: string;
  children?: React.ReactNode | string;
  icon?: React.ElementType;
  text?: string;
}

function NavMenuLink(props: NavMenuLinkProps) {
  const { children, icon, text, ...linkProps } = props;
  const location = useLocation();

  const IconComponent = icon;

  return (
    <ListItem selected={location.pathname === linkProps.to} button component={Link} {...linkProps}>
      {icon && (
        <ListItemIcon>
          <IconComponent />
        </ListItemIcon>
      )}
      {text && <ListItemText primary={text} />}
      {children}
    </ListItem>
  );
}

interface NavMenuProps {
  children?: React.ReactNode;
}

function NavMenu(props: NavMenuProps) {
  const classes = useStyles(props);
  const title = '2nd Chair Analytics';

  return (
    <div>
      <div className={classes.toolbar}>
        <Box>{title && <Typography variant="h6">{title}</Typography>}</Box>
      </div>
      <Divider />
      <List>
        {contentPages.map(({ label, href, icon }, index) => (
          <NavMenuLink key={index} to={href} icon={icon} text={label} />
        ))}
      </List>
      <Divider />
      <List>
        {otherPages.map(({ label, href, icon }, index) => (
          <NavMenuLink key={index} to={href} icon={icon} text={label} />
        ))}
      </List>
    </div>
  );
}

export default NavMenu;
