import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';
import {
  ArrowBack as BackIcon,
  Dashboard as OverviewIcon,
  PersonAdd as JurorsIcon,
  FormatListNumbered as QuestionsIcon,
  People as VoirDireIcon,
  FindInPage as ReviewIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
    },
    '#root': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    maxHeight: '100%',
    height: '1%'
  },
  nav: {
    borderTop: `1px solid ${theme.palette.divider}`,
    zIndex: 99
  },
}));

export interface ISheetLayout {
  children?: React.ReactNode;
  title?: React.ReactNode;
  actions?: React.ReactChild;
}

const BottomNavLayout: React.FC<ISheetLayout> = ({
  children,
  title = 'Untitled case',
  actions,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { caseId } = useParams();
  const location = useLocation();
  const activeRoute = location.pathname.match(/\/[a-zA-Z^/]*$/g)?.[0] ?? '/';
  const [activePage, setActivePage] = React.useState(activeRoute);

  return (
    <>
      <AppBar elevation={1} color="primary" position="relative" variant="outlined">
        <Toolbar>
          <IconButton
            component={Link}
            to="/cases"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            className={classes.menuButton}
          >
            <BackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {title}
          </Typography>
          {/* {actions} */}
        </Toolbar>
      </AppBar>
      <div className={classes.content}>{children}</div>
      <BottomNavigation
        className={classes.nav}
        value={activePage}
        onChange={(_, newValue) => {
          history.push(`/cases/${caseId}${newValue}`);
          setActivePage(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction value="/" label="Overview" icon={<OverviewIcon />} />
        <BottomNavigationAction value="/jurors" label="Jurors" icon={<JurorsIcon />} />
        <BottomNavigationAction value="/questions" label="Questions" icon={<QuestionsIcon />} />
        <BottomNavigationAction value="/voirdire" label="Voir Dire" icon={<VoirDireIcon />} />
        <BottomNavigationAction value="/review" label="Review" icon={<ReviewIcon />} />
      </BottomNavigation>
    </>
  );
};

export default BottomNavLayout;
