import { useQuery } from 'react-query';
import { HCADResponse, ZipData } from 'state/interfaces';

export const fetchCase = (search: string) => (): Promise<HCADResponse> =>
  fetch(`/api/proxy/hcad?name=${search}`, {
    method: 'GET',
  }).then((res) => res.json());

export const fetchCode = (zip: string) => (): Promise<ZipData[]> =>
  fetch(`/api/proxy/zip?code=${zip}`).then((res) => res.json());

export function useSearchByNameHCAD(search: string) {
  return useQuery({
    queryKey: ['hcad', search],
    queryFn: fetchCase(search),
    enabled: search !== '',
    staleTime: 1000 * 60 * 60,
  });
}

export function useGetCityByZipCode(zip: string) {
  return useQuery({
    queryKey: ['zip-code', zip],
    queryFn: fetchCode(zip),
    enabled: zip !== '',
    staleTime: 1000 * 60 * 60,
  });
}
