import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { List, IconButton, Avatar } from '@material-ui/core';
import { CustomSlider, JurorListItem, Spinner } from 'components';
import { useAtom } from 'jotai';
import { activeJuror, activeQuestion } from 'store';
import { useGetAllJurorsByCaseId } from 'hooks/queries/useJurorQueries';
import { useGetAllStrikes } from 'hooks/queries/useStrikeQueries';
import { useGetAllResponsesByQuestion, useUpdateResponse } from 'hooks/queries/useResponsesQueries';
import { reverseSortJurors } from 'state/reducers';
import { IScaleOptions, ITextOptions } from 'state/interfaces';
import { omit, values, mapKeys } from 'lodash';

const useStyles = makeStyles(theme => ({
  list: {
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  avatar: {
    height: 40,
    width: 40,
    fontSize: '1.2rem',
  },
  iconButton: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  selected: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
  },
  deselected: {
    color: theme.palette.primary.main,
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  slider: {
    maxWidth: 500,
  },
}));

const MultiListItemResponse = ({ question, jurorId, onUpdate, response }) => {
  const classes = useStyles();
  const [state, setState] = React.useState(
    response
      ? values(omit(response.response, ['notes']))
      : Array(question?.options?.choices.length)
          .fill(null)
          .map(() => false),
  );

  const createHandleClick = index => () => {
    setState(state => {
      if (!state) return state;
      const _state = state?.slice();
      _state[index] = !_state[index];
      onUpdate(jurorId, {
        ...mapKeys(_state, function(value, index) {
          return index;
        }),
        notes: response?.response?.notes || '',
      });
      return _state;
    });
  };

  return (
    <>
      {question?.options.choices.map((choice, i) => (
        <IconButton
          className={classes.iconButton}
          key={`choice-${i}`}
          size="small"
          onClick={createHandleClick(i)}
        >
          <Avatar
            className={classnames(classes.avatar, state[i] ? classes.selected : classes.deselected)}
          >
            {choice.prefix}
          </Avatar>
        </IconButton>
      ))}
    </>
  );
};

const ScaleListItemResponse = ({ question, jurorId, initialResponse, onUpdate }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(initialResponse?.value || question.options.default);

  const handleScaleChange = (e, newValue) => {
    if (value !== newValue) {
      onUpdate(jurorId, { value: newValue, notes: initialResponse?.notes || '' });
      setValue(newValue);
    }
  };

  return (
    <CustomSlider
      className={classes.slider}
      value={value}
      onChange={handleScaleChange}
      min={1}
      max={question.options.max}
      step={1}
      marks
    />
  );
};

const VoirDireJurorsList = () => {
  const classes = useStyles();
  const { caseId } = useParams();
  const [currentJuror, setCurrentJuror] = useAtom(activeJuror);
  const [currentQuestion] = useAtom(activeQuestion);
  const { data: jurorsData } = useGetAllJurorsByCaseId(caseId);
  const { data: strikeData } = useGetAllStrikes(caseId);
  const { data: responsesData, refetch: refetchResponses } = useGetAllResponsesByQuestion(
    caseId,
    currentQuestion?.id,
  );

  const jurors = useMemo(() => (jurorsData ? reverseSortJurors(jurorsData) : []), [jurorsData]);
  const strikesCase = useMemo(() => (strikeData ? strikeData : null), [strikeData]);
  const responsesQuestion = useMemo(() => (responsesData ? responsesData : null), [responsesData]);

  const handleSelect = (juror_number: number) => {
    //@ts-ignore
    setCurrentJuror(jurors.find(juror => juror.juror_number === juror_number));
  };

  const updateResponse = useUpdateResponse(caseId);
  const handleUpdate = (jurorId: string, updates: ITextOptions | IScaleOptions) => {
    updateResponse
      .mutateAsync({
        question_id: currentQuestion.id,
        juror_id: jurorId,
        response: updates,
        isText: false,
      })
      .then(() => {
        refetchResponses();
      });
  };

  if (!strikesCase || !responsesQuestion) return <Spinner />;

  return (
    <List className={classes.list}>
      {jurors.map(({ juror_id, juror_number, first_name, last_name }) => {
        const response = responsesQuestion.find(response => response.juror_id === juror_id);
        const strike = strikesCase.some(strike => strike.juror_id === juror_id);

        const key = `${currentJuror?.juror_id}${currentQuestion?.id}${
          currentQuestion?.type !== 'freetext' ? JSON.stringify(response?.response ?? {}) : ''
        }`;

        return (
          <JurorListItem
            key={juror_id + currentQuestion.id}
            active={Boolean(currentJuror?.juror_number === juror_number)}
            response={!!response}
            strike={strike}
            juror_id={juror_id}
            juror_number={juror_number}
            first_name={first_name}
            last_name={last_name}
            onSelect={handleSelect}
          >
            {currentQuestion?.type === 'multi' && (
              <MultiListItemResponse
                key={key + '-multi'}
                question={currentQuestion}
                jurorId={juror_id}
                onUpdate={handleUpdate}
                response={response}
              />
            )}
            {currentQuestion?.type === 'scale' && (
              <ScaleListItemResponse
                key={key + '-scale'}
                initialResponse={response?.response}
                question={currentQuestion}
                jurorId={juror_id}
                onUpdate={handleUpdate}
              />
            )}
          </JurorListItem>
        );
      })}
    </List>
  );
};

export default VoirDireJurorsList;
