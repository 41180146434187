import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Button } from '@material-ui/core';
import {
  JurorsList,
  EditJurorForm,
  Spinner,
  EmptyState,
  DraggableJurorsList,
  ImportModal,
} from 'components';
import {
  useGetAllJurorsByCaseId,
  useUpdateJuror,
  useReorderJurors,
} from 'hooks/queries/useJurorQueries';
import { sortJurors } from 'state/reducers';
import { useAtom } from 'jotai';
import { activeJuror, activeJurorNumber } from 'store';
import { IJuror } from 'state/interfaces';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
  },
  sidebar: {
    flexBasis: 550,
    maxHeight: '100%',
    overflow: 'auto',
    background: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  content: {
    width: '100%',
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  listActions: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Jurors = () => {
  const classes = useStyles();
  const { caseId } = useParams();
  const [currentJurorNumber, setCurrentJurorNumber] = useAtom(activeJurorNumber);
  const [mode, setMode] = React.useState<'edit' | 'reorder'>('edit');
  const [orderedJurors, setOrderedJurors] = React.useState<IJuror[]>([]);
  const [isSaving, setSaving] = React.useState(false);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [juror, setJuror] = useAtom(activeJuror);

  const { data: jurorData, isLoading, refetch } = useGetAllJurorsByCaseId(caseId);
  const sortedJurors = useMemo(() => (jurorData ? sortJurors(jurorData) : []), [jurorData]);

  const updateJuror = useUpdateJuror(caseId);
  const reorderJurors = useReorderJurors(caseId);

  if (isLoading) {
    return <Spinner />;
  }

  const handleJurorSelect = (jurorNumber: number) => {
    setCurrentJurorNumber(jurorNumber);
    // @ts-ignore
    setJuror(sortedJurors.find(juror => juror.juror_number === jurorNumber));
  };

  const handleJurorUpdate = async (juror_id, updates) => {
    updateJuror
      .mutateAsync({
        juror_id,
        ...updates,
      })
      .then(() => refetch())
      .catch(e => {
        console.error(e);
      });
  };

  const handleNextJuror = () => {
    handleJurorSelect(currentJurorNumber + 1);
  };

  const handlePreviousJuror = () => {
    handleJurorSelect(currentJurorNumber - 1);
  };

  const handleEnableReorder = () => {
    setCurrentJurorNumber(null);
    setMode('reorder');
  };

  const handleCancelReorder = () => {
    setOrderedJurors(null);
    setMode('edit');
  };

  const handleSaveReorder = () => {
    if (!orderedJurors) return;

    setSaving(true);
    const newJurorsOrder = orderedJurors.map(({ juror_id, juror_number }) => ({
      juror_id,
      juror_number,
    }));

    reorderJurors.mutateAsync({ newJurorsOrder }).then(() => {
      setOrderedJurors(null);
      setMode('edit');
      setSaving(false);
      refetch();
    });
  };

  const handleOrderUpdate = (newJurorOrder: IJuror[]) => {
    setOrderedJurors(newJurorOrder);
  };

  const handleImportClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    if (isSaving) return;
    setModalOpen(false);
    refetch();
  };

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <aside className={classes.sidebar}>
          <div className={classes.listActions}>
            {mode === 'edit' && (
              <React.Fragment>
                <Button onClick={handleEnableReorder} color="primary" variant="outlined">
                  Reorder Jurors
                </Button>
                <Button onClick={handleImportClick} color="primary" variant="contained">
                  Import Data
                </Button>
              </React.Fragment>
            )}
            {mode === 'reorder' && (
              <React.Fragment>
                <Button onClick={handleCancelReorder} disabled={isSaving} variant="outlined">
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveReorder}
                  disabled={!orderedJurors || isSaving}
                  color="primary"
                  variant="contained"
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              </React.Fragment>
            )}
          </div>
          {mode === 'edit' && (
            <JurorsList
              jurors={sortedJurors}
              onSelect={handleJurorSelect}
              activeJurorNumber={currentJurorNumber}
            />
          )}
          {mode === 'reorder' && (
            <DraggableJurorsList
              jurors={sortedJurors}
              jurorState={orderedJurors}
              onOrderUpdate={handleOrderUpdate}
            />
          )}
        </aside>
        <main className={classnames(classes.content)}>
          {currentJurorNumber ? (
            <Card variant="outlined">
              <CardContent>
                <EditJurorForm
                  key={juror.juror_id || juror.id}
                  juror={juror}
                  jurorNumber={currentJurorNumber}
                  onJurorUpdate={handleJurorUpdate}
                  onNextJuror={handleNextJuror}
                  onPreviousJuror={handlePreviousJuror}
                  disablePreviousJuror={currentJurorNumber <= 1}
                  disableNextJuror={currentJurorNumber >= sortedJurors?.length}
                />
              </CardContent>
            </Card>
          ) : (
            <EmptyState>
              {mode === 'edit'
                ? 'Select a Juror on the left hand side to begin editing!'
                : 'Click and drag jurors to reorder, and click the "Save" button when you are finished.'}
            </EmptyState>
          )}
        </main>
      </div>
      <ImportModal open={isModalOpen} onClose={handleModalClose} />
    </div>
  );
};

export default Jurors;
