import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.text.hint,
    maxWidth: 400,
    textAlign: 'center',
  },
}));

interface EmptyStateProps {
  children?: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({ children = 'No Text Specified' }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.text}>
        {children}
      </Typography>
    </div>
  );
};

export default EmptyState;
