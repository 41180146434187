import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { headerCase } from 'change-case';
import { AskingPartyBadger } from 'components';
import { IQuestion } from 'state/interfaces';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: theme.spacing(-1),
    fontWeight: 'bold',
  },
  list: {
    listStyleType: 'none',
    margin: 0,
    marginBottom: theme.spacing(1),
    padding: 0,
  },
  listItem: {
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  party: {
    fontWeight: 'bold',
  },
  grow: {
    flexGrow: 1,
  },
}));

const StrikesList: React.FC<{ jurorId: string; strikes: any; questions: IQuestion[] }> = ({
  jurorId,
  strikes,
  questions,
}) => {
  const classes = useStyles();
  const allStrikes: any[] = strikes?.[jurorId];

  return (
    <>
      <p className={classes.heading}>Strikes</p>
      <ul className={classes.list}>
        {Object.entries(allStrikes).map(([party, strike]) => {
          let index = -1;
          const question =
            questions.find((question, i) => {
              if (question.id === strike?.question_id) {
                index = i;
                return question;
              }
              return false;
            }) ?? null;

          return (
            <li className={classes.listItem} key={strike.strike_id}>
              <span>
                <AskingPartyBadger party={party} size="small" />
              </span>
              <span className={classes.party}>{headerCase(party)} - </span>
              <span className={classes.grow}>
                {question ? `Q${index + 1}: ${question.question}` : '(Not based on a question)'}
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default StrikesList;
