import fetch from 'isomorphic-unfetch';
import cookie from 'js-cookie';
import Client from './Client';
import Resource from './Resource';

const API_URL = '/api';

const client = new Client(fetch, {
  baseUrl: API_URL,
  getClientToken: () => cookie.get('token'),
  getServerToken: () => {},
});

export { Client, Resource };
export default client;
