import { useQuery, useMutation, QueryClient } from 'react-query';
import {
  IQuestionResponse,
  IQuestionResponseWithJuror,
  IScaleOptions,
  ITextOptions,
} from 'state/interfaces';
import api from 'utils/api';

const queryClient = new QueryClient();

export const fetchAllResponses = (caseId: string) => async (): Promise<
  IQuestionResponseWithJuror[]
> =>
  api
    .cases(caseId)
    .responses()
    .get();

export const fetchAllResponsesByQuestion = (
  caseId: string,
  activeQuestionId: string,
) => async (): Promise<IQuestionResponse[]> =>
  api
    .cases(caseId)
    .questions(activeQuestionId)
    .responses()
    .get();

export const createResponse = (caseId: string) => ({
  question_id,
  ...payload
}: {
  question_id: string;
  payload: Partial<IQuestionResponse>;
}): Promise<IQuestionResponse> => {
  return api
    .cases(caseId)
    .questions(question_id)
    .responses()
    .post(payload);
};

export const updateResponse = (caseId: string) => ({
  juror_id,
  question_id,
  response,
  isText = false,
}: {
  juror_id: string;
  question_id: string;
  response: ITextOptions | IScaleOptions;
  isText: boolean;
}): Promise<IQuestionResponse> => {
  return api
    .cases(caseId)
    .questions(question_id)
    .respond()
    .post({ juror_id, question_id, response });
};

export const deleteResponseById = (caseId: string) => ({
  questionId,
  responseId,
}: {
  questionId: string;
  responseId: string;
}): Promise<IQuestionResponse> =>
  api
    .cases(caseId)
    .questions(questionId)
    .responses(responseId)
    .delete();

export function useGetAllResponses(caseId: string) {
  return useQuery({
    queryKey: ['resposes', caseId],
    queryFn: fetchAllResponses(caseId),
  });
}

export function useGetAllResponsesByQuestion(caseId: string, activeQuestionId: string) {
  return useQuery({
    queryKey: ['question_resposes', activeQuestionId],
    queryFn: fetchAllResponsesByQuestion(caseId, activeQuestionId),
    enabled: !!activeQuestionId,
  });
}

function invalidateQueries(caseId: string, question_id: string) {
  queryClient.invalidateQueries(['resposes', caseId]);
  queryClient.invalidateQueries(['question_resposes', question_id]);
}

export function useAddResponse(caseId: string) {
  return useMutation({
    mutationFn: createResponse(caseId),
    onMutate: newResponse => {
      queryClient.cancelQueries(['resposes', caseId]);
      queryClient.cancelQueries(['question_resposes', newResponse.question_id]);
    },
    onSettled: data => {
      invalidateQueries(caseId, data.question_id);
    },
  });
}

export function useUpdateResponse(caseId: string) {
  return useMutation({
    mutationFn: updateResponse(caseId),
    onMutate: updatedResponse => {
      if (updatedResponse.isText) {
        queryClient.cancelQueries(['resposes', caseId]);
        queryClient.cancelQueries(['question_resposes', updatedResponse.question_id]);
      }
    },
    onSettled: data => {
      invalidateQueries(caseId, data.question_id);
    },
  });
}

export function useRemoveRespose(caseId: string) {
  return useMutation({
    mutationFn: deleteResponseById(caseId),
    onMutate: deletedResponse => {
      queryClient.cancelQueries(['resposes', caseId]);
      queryClient.cancelQueries(['question_resposes', deletedResponse.questionId]);
    },
    onSuccess: (data, { questionId }) => invalidateQueries(caseId, questionId),
  });
}
