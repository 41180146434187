// @ts-nocheck

import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { JurorCard, Spinner } from 'components';
import { useParams } from 'react-router-dom';
import { activeJuror, activeQuestion } from 'store';
import { useAtom } from 'jotai';
import { useAddStrike, useGetAllStrikes, useRemoveStrike } from 'hooks/queries/useStrikeQueries';
import { useGetAllJurorsByCaseId } from 'hooks/queries/useJurorQueries';
import { useGetAllResponsesByQuestion } from 'hooks/queries/useResponsesQueries';
import { reverseSortJurors } from 'state/reducers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const VoirDireJurorsGrid = () => {
  const classes = useStyles();
  const { caseId } = useParams();
  const [currentJuror, setCurrentJuror] = useAtom(activeJuror);
  const [currentQuestion] = useAtom(activeQuestion);
  const { data: jurorsData } = useGetAllJurorsByCaseId(caseId);
  const { data: strikeData, refetch: refetchStrikes } = useGetAllStrikes(caseId);
  const { data: responsesData } = useGetAllResponsesByQuestion(caseId, currentQuestion?.id);

  const jurors = useMemo(() => (jurorsData ? reverseSortJurors(jurorsData) : []), [jurorsData]);
  const strikesCase = useMemo(() => (strikeData ? strikeData : null), [strikeData]);
  const responsesQuestion = useMemo(() => (responsesData ? responsesData : null), [responsesData]);

  const handleSelectJuror = (juror_number: number) => {
    setCurrentJuror(jurors.find(juror => juror.juror_number === juror_number));
  };

  const addStrike = useAddStrike(caseId);
  const createStrikeHandler = (juror_id: string) => (striking_party: string) => {
    addStrike
      .mutateAsync({
        juror_id,
        striking_party,
        question_id: currentQuestion.id,
      })
      .then(() => {
        refetchStrikes();
      });
  };

  const removeStrike = useRemoveStrike(caseId);
  const createStrikeDeleteHandler = juror_id => () => {
    const strike = strikesCase.find(strike => strike.juror_id === juror_id);
    if (strike) {
      removeStrike.mutateAsync(strike.strike_id).then(() => {
        refetchStrikes();
      });
    }
  };

  if (!strikesCase || !responsesQuestion) return <Spinner />;

  return (
    <Grid className={classes.root} direction="row-reverse" container spacing={3}>
      {jurors.map(({ juror_id, juror_number, first_name, last_name }) => (
        <Grid item xs={6} sm={6} md={4} lg={3} key={juror_id}>
          <JurorCard
            key={juror_id + currentQuestion?.id}
            active={juror_number === currentJuror?.juror_number}
            strike={strikesCase.some(strike => strike.juror_id === juror_id)}
            response={responsesQuestion.some(response => response.juror_id === juror_id)}
            juror_number={juror_number}
            first_name={first_name}
            last_name={last_name}
            onSelect={handleSelectJuror}
            onStrike={createStrikeHandler(juror_id)}
            onStrikeDelete={createStrikeDeleteHandler(juror_id)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default VoirDireJurorsGrid;
