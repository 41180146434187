import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab,
  FormLabel,
  Button,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import { useGetAllJurorsByCaseId, useAddJuror } from 'hooks/queries/useJurorQueries';
import api from 'utils/api';
import { mapJurorsAsObject } from 'state/reducers';
import { useGetCityByZipCode, useSearchByNameHCAD } from 'hooks/queries/useHCADQueries';
import { debounce } from 'lodash';
import { HcadData, IJuror } from 'state/interfaces';
import { splitName } from 'utils/text';
import { useAtom } from 'jotai';
import { activeJuror, activeJurorNumber } from 'store';
import AlertMessage from 'components/UI/AlertComponent';

const jurorFields = new Set([
  'juror_id',
  'first_name',
  'middle_name',
  'last_name',
  'children',
  'gender',
  'race',
  'marital_status',
  'education',
  'date_of_birth',
  'tdl_number',
  'mailing_address',
  'city',
  'county',
  'state',
  'phone_number',
  'has_sustained_medical_injury',
  'medical_injury_description',
  'occupation',
  'employer',
  'employment_length',
  'work_phone_number',
  'spouse_name',
  'spouse_occupation',
  'spouse_employer',
  'spouse_employment_length',
  'is_us_citizen',
  'has_been_in_criminal_case',
  'has_served_on_civil_jury',
  'has_served_on_criminal_jury',
  'number_of_children',
]);

function createJurorData(caseId, jurorsData, csvFile = null) {
  let updates;
  if (csvFile) {
    // Handle overriding data with CSV files instead
    return new Promise((resolve, reject) => {
      Papa.parse(csvFile, {
        header: true,
        dynamicTyping: true,
        complete: (results: any) => {
          let updates = [];
          let newData = results.data;

          for (let [numStr, existingJuror] of Object.entries(jurorsData)) {
            const num = Number(numStr);
            if (newData[num - 1]) {
              // @ts-ignore
              updates.push({ ...newData[num - 1], juror_id: existingJuror.juror_id });
            }
          }

          api
            .cases(caseId)
            .jurors()
            .populate()
            .post({
              updates,
              method: 'csv',
            })
            .then(resolve);
        },
      });
    });
  } else {
    // Send empty stuff for random data to be generated
    updates = Object.values(jurorsData).map((juror: any) => {
      for (let key in juror) {
        if (!jurorFields.has(key)) {
          delete juror[key];
        }
      }
      return juror;
    });
    return api.cases(caseId).jurors().populate().post({ updates, method: 'random' });
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: '90%',
    width: 600,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  form: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  layout: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '8px',
    '& > div': {
      display: 'grid',
      justifyItems: 'center',
    },
  },
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ImportModal = ({ open, onClose }) => {
  const classes = useStyles();
  const { caseId } = useParams();
  const { data: jurorsData, isLoading } = useGetAllJurorsByCaseId(caseId);
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState<HcadData>(null);
  const { data: hcadData } = useSearchByNameHCAD(search);
  const { data: zipData } = useGetCityByZipCode(selected?.zip || '');
  const [mode, setMode] = React.useState<number>(0);
  const fileInputRef = React.useRef();
  const [, setJuror] = useAtom(activeJuror);
  const [, setCurrentJurorNumber] = useAtom(activeJurorNumber);
  const [message, setMessage] = React.useState('');

  const jurorsMap = useMemo(() => (jurorsData ? mapJurorsAsObject(jurorsData) : []), [jurorsData]);

  const hcad = useMemo(() => (hcadData ? hcadData.data : []), [hcadData]);
  const zip = useMemo(() => (zipData ? zipData[0] : null), [zipData]);

  const handleClose = () => {
    onClose();
  };

  const handleModeChange = (_, newMode) => {
    setMode(newMode);
  };

  const handleCSVSubmit = (e) => {
    e.preventDefault();

    const fileInput = fileInputRef.current;

    if (!jurorsMap || !fileInput) {
      return;
    }

    // @ts-ignore
    createJurorData(caseId, jurorsMap, fileInput.files[0]).then((res) => {
      handleClose();
    });
  };

  const handleRandomSubmit = (e) => {
    e.preventDefault();

    if (!jurorsMap) {
      return;
    }

    createJurorData(caseId, jurorsMap).then((res) => {
      handleClose();
    });
  };

  const debouncedSearch = debounce((value: string) => {
    if (search === value || selected?.name === value) return;
    if (value.split(' ').length < 2) return;
    setSearch(value);
  }, 500);

  const handleSelectName = (newSelected: HcadData) => {
    setSelected(newSelected);
  };

  const createJuror = useAddJuror(caseId);
  const handleCreateJuror = () => {
    const { first_name, middle_name, last_name } = splitName(selected.name);
    const payload: Partial<IJuror> = {
      case_id: caseId,
      first_name,
      middle_name,
      last_name,
      mailing_address: selected.address,
      zip_code: +selected.zip,
      city: zip?.city,
      state: zip?.state,
      juror_number: jurorsData.length,
    };

    return createJuror.mutateAsync(payload).then((result) => {
      //@ts-ignore
      setJuror(result);
      setCurrentJurorNumber(result.juror_number);
      setMessage(selected.name + ' added');
      setSelected(null);
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      {!isLoading ? (
        <div className={classes.root}>
          <Typography gutterBottom variant="h5">
            Import Juror Data
          </Typography>
          <AppBar color="transparent" position="static">
            <Tabs value={mode} onChange={handleModeChange} centered>
              <Tab value={0} label=".CSV" {...a11yProps(0)}></Tab>
              <Tab value={1} label="Random" {...a11yProps(1)}></Tab>
              <Tab value={2} label="HCAD" {...a11yProps(2)}></Tab>
            </Tabs>
          </AppBar>
          <TabPanel value={mode} index={0}>
            <form className={classes.form} onSubmit={handleCSVSubmit}>
              <FormLabel htmlFor="csv-file">.CSV File</FormLabel>
              <Typography variant="body2">
                <input type="file" name="csv-file" id="csv-file" ref={fileInputRef} required />
              </Typography>
              <Button color="primary" variant="contained" type="submit">
                Upload Data
              </Button>
            </form>
          </TabPanel>
          <TabPanel value={mode} index={1}>
            <form className={classes.form} onSubmit={handleRandomSubmit}>
              <Typography variant="body2">
                This will generate random data for your jurors
              </Typography>
              <Button color="primary" variant="contained" type="submit">
                Generate Data
              </Button>
            </form>
          </TabPanel>
          <TabPanel value={mode} index={2}>
            <Autocomplete
              id="hcad-search-field"
              freeSolo
              disableClearable
              onInputChange={(event, newInputValue: string) => {
                debouncedSearch(newInputValue);
              }}
              onChange={(event, newValue: HcadData) => {
                handleSelectName(newValue);
              }}
              options={hcad}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <React.Fragment>
                  {option.name} -- {option.address}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search at Harris Central Appraisal District"
                  size="medium"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            {selected ? (
              <Accordion square expanded={true}>
                <AccordionDetails>
                  <div className={classes.layout}>
                    <div>
                      <h4>Name: </h4>
                      <Typography>{selected.name}</Typography>
                    </div>
                    <div>
                      <h5>Address: </h5>
                      <Typography>{selected.address}</Typography>
                    </div>
                    <div>
                      <h5>City: </h5>
                      <Typography>{zip?.city}</Typography>
                    </div>
                    <div>
                      <h5>State: </h5>
                      <Typography>{zip?.state}</Typography>
                    </div>
                  </div>
                </AccordionDetails>
                <AccordionActions>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={handleCreateJuror}
                    disabled={!zip}
                  >
                    Add Juror
                  </Button>
                </AccordionActions>
              </Accordion>
            ) : (
              <Typography variant="body2">
                To search in Harris Central Appraisal District you must enter a last name and a
                first name. These two fields can be abbreviated. Examples: 'Jimenez Daniel',
                'Jimenez D', 'Jim Daniel'.
              </Typography>
            )}
          </TabPanel>
          {message && <AlertMessage message={message} severity="success" key={message} />}
        </div>
      ) : null}
    </Dialog>
  );
};

export default ImportModal;
