import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent, TextField } from '@material-ui/core';
import { CustomSlider } from 'components';

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: theme.spacing(5),
  },
}));

interface ScaleResponseFormProps {
  question: {
    question: string;
    options: {
      default: number;
      max: number;
      notes: string;
    };
  };
  initialResponse: {
    value: number;
    notes: string;
  };
  onUpdate: Function;
}

const ScaleResponseForm: React.FC<ScaleResponseFormProps> = ({
  question,
  initialResponse,
  onUpdate,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    value: initialResponse?.value ?? question.options.default,
    notes: initialResponse?.notes ?? question.options.notes,
  });
  const isMountingRef = React.useRef(true);

  const handleScaleChange = (e, newValue) => {
    const notes = state.notes ? state.notes : ' ';
    setState({ notes, value: newValue });
  };

  const handleNotesChange = (e) => {
    setState({ ...state, notes: e.target.value });
  };

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isMountingRef.current) {
      timeout = setTimeout(() => {
        onUpdate(state);
      }, 100);
    } else {
      timeout = setTimeout(() => {}, 500);
      isMountingRef.current = false;
    }

    return () => clearTimeout(timeout);
  }, [state, onUpdate]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {question.question}
        </Typography>
        <CustomSlider
          defaultValue={1}
          value={state.value}
          className={classes.slider}
          onChange={handleScaleChange}
          min={1}
          max={question.options.max}
          step={1}
          marks
        />
        <TextField
          variant="outlined"
          rows={6}
          rowsMax={6}
          fullWidth
          value={state.notes}
          onChange={handleNotesChange}
          multiline
        />
      </CardContent>
    </Card>
  );
};

export default ScaleResponseForm;
