import React from 'react';
import QuestionsList from 'components/questions/QuestionsList';

interface IQuestionSwapOverlayProps {
  onSelectedQuestion: Function;
}

const QuestionSwapOverlay: React.FC<IQuestionSwapOverlayProps> = ({ onSelectedQuestion }) => {
  return <QuestionsList onSelectedQuestion={onSelectedQuestion} />;
};

export default QuestionSwapOverlay;
