import React from 'react';
import {
  Chat as FreeTextIcon,
  List as MultiIcon,
  LinearScale as ScaleIcon,
} from '@material-ui/icons';

const questionsIcons = {
  freetext: FreeTextIcon,
  multi: MultiIcon,
  scale: ScaleIcon,
};

interface QuestionsIconProps {
  type: 'freetext' | 'scale' | 'multi';
  className: string;
}

const QuestionsIcon: React.FC<QuestionsIconProps> = ({ type, ...props }) => {
  const Icon = questionsIcons[type];
  return <Icon {...props} />;
};

export default QuestionsIcon;
