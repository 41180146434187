import React from 'react';
import { useParams, useRouteMatch, Switch, Route } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { BottomNavLayout } from 'components/layouts';
import { Overview, Questions, Jurors, VoirDire, Strikes } from 'pages/Case';
import { useGetCase } from 'hooks/queries/useCaseQueries';

const Case = () => {
  const { caseId } = useParams();
  const { path } = useRouteMatch();
  const { status, data } = useGetCase(caseId);

  const caseTitle = React.useMemo(() => {
    if (status !== 'success') return;
    if (!data.plaintiff || !data.defendant) return;
    return `${data.plaintiff} v. ${data.defendant}`;
  }, [status, data]);

  return (
    <BottomNavLayout
      title={caseTitle || '...'}
      actions={<TextField variant="outlined" size="small" label="Search this case" />}
    >
      <Switch>
        <Route exact path={path}>
          <Overview />
        </Route>
        <Route path={`${path}/jurors`}>
          <Jurors />
        </Route>
        <Route path={`${path}/questions`}>
          <Questions />
        </Route>
        <Route path={`${path}/voirdire`}>
          <VoirDire />
        </Route>
        <Route path={`${path}/review`}>
          <Strikes />
        </Route>
      </Switch>
    </BottomNavLayout>
  );
};

export default Case;
