import { createMuiTheme } from '@material-ui/core/styles';
import {
  deepPurple,
  deepOrange,
  grey,
  pink,
  green,
  indigo,
  teal,
  orange,
} from '@material-ui/core/colors';

// const SECOND_CHAIR_PURPLE = '#42425c';
// const SECOND_CHAIR_LIGHT_PURPLE = '#f0f0f7';

const typography = {
  htmlFontSize: 16,
  fontSize: 12,
  fontFamily: '"Inter", "Arial", sans-serif',
};

export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: deepPurple[700],
    },
    secondary: {
      main: deepOrange[700],
    },
    background: {
      default: grey[50],
    },
    prosecution: { main: indigo[500] },
    defense: { main: orange[700] },
    judge: { main: teal[500] },
    confirm: { main: green[500] },
    deny: { main: pink[500] },
  },
  typography: {
    ...typography,
    button: {
      textTransform: 'none',
    },
  },
});

export const invertedTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: grey[50],
      contrastText: deepPurple[700],
    },
    background: {
      default: deepPurple[700],
    },
    text: {
      primary: grey[50],
    },
  },
  typography: {
    ...typography,
    button: {
      textTransform: 'none',
    },
  },
});
