import { resolve } from '../url';

class Resource implements ApiResource {
  id: string;
  name: string;
  client: ApiClient;
  config: ResourceConfig;
  parent: Resource;
  get: HttpAction;
  post: HttpAction;
  put: HttpAction;
  patch: HttpAction;
  delete: HttpAction;
  questions: (id?: string) => Resource;
  jurors: (id?: string) => Resource;
  responses: (id?: string) => Resource;
  respond: (id?: string) => Resource;
  strikes: (id?: string) => Resource;
  confirmation: (id?: string) => Resource;
  reorder: (id?: string) => Resource;
  populate: (id?: string) => Resource;
  confirmations: (id?: string) => Resource;

  constructor(client, name: string, config: ResourceConfig, id?: string, parent?: Resource) {
    if (parent) {
      this.parent = parent;
    }
    if (id) {
      this.id = id;
    }
    this.client = client;
    this.name = name;
    this.config = config || {};
    for (let method of config?.methods ?? []) {
      this[method] = this.createAction(method);
    }
    for (let key in config?.children ?? {}) {
      const child = config.children[key];
      this[key] = child(this);
    }
    this.createAction = this.createAction.bind(this);
  }

  static create(client: ApiClient, name: string, config?: ResourceConfig): () => Resource {
    return (id?: string) => {
      const resource = new Resource(client, name, config, id);
      return resource;
    };
  }

  static createChild(client: ApiClient, name: string, config?: ResourceConfig): Function {
    return (parent: Resource) => {
      return (id?: string) => {
        const resource = new Resource(client, name, config, id, parent);
        return resource;
      };
    };
  }

  private createAction = (method: string): HttpAction => {
    let urlFragments = [];
    let node: Resource = this;
    do {
      // traverse node list and build url
      if (node.id) {
        urlFragments.unshift(node.id);
      }
      urlFragments.unshift(node.name);
      node = node.parent;
    } while (Boolean(node));
    const options: RequestConfig = {
      url: resolve(...urlFragments),
      method: method.toUpperCase(),
    };
    return <T>(bodyOrQuery) => {
      if (method.toLowerCase() === 'get') {
        options.query = bodyOrQuery;
      } else {
        options.body = bodyOrQuery;
      }
      return this.client.request<T>(options);
    };
  };
}

export default Resource;
