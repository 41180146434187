import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    minWidth: 0,
  },
  label: {},
}));

interface MultiResponseFormProps {
  question: {
    id: string;
    question: string;
    options: {
      choices: [
        {
          prefix: string;
          description: string;
        },
      ];
    };
  };
  initialResponse: any;
  onUpdate: Function;
}

function getInitialNotes(question, initialResponse) {
  if (initialResponse && initialResponse.notes) return initialResponse.notes;
  if (question && question.options.notes) return question.options.notes;
  return '';
}

const MultiResponseForm: React.FC<MultiResponseFormProps> = ({
  question,
  initialResponse = {},
  onUpdate,
}) => {
  const classes = useStyles();
  const unmountedRef = React.useRef<boolean>(false);
  const isMountingRef = React.useRef(true);
  const [checked, setChecked] = React.useState(initialResponse);
  const [notes, setNotes] = React.useState(getInitialNotes(question, initialResponse));
  const check = (index) => setChecked({ ...checked, [index]: !checked[index] });

  React.useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isMountingRef.current) {
      timeout = setTimeout(() => {
        onUpdate({ ...checked, notes });
      }, 200);
    } else {
      timeout = setTimeout(() => {}, 500);
      isMountingRef.current = false;
    }

    return () => {
      if (unmountedRef.current) return;
      clearTimeout(timeout);
    };
  }, [checked, notes, onUpdate]);

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {question.question}
        </Typography>
        <List>
          {question.options.choices.map((choice, i) => (
            <ListItem button key={`multi-${i}`} onClick={() => check(i)}>
              <ListItemIcon className={classes.checkbox}>
                <Checkbox
                  edge="start"
                  checked={checked[i] || false}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': choice.prefix }}
                />
              </ListItemIcon>
              <ListItemText id={choice.prefix} className={classes.label}>
                <Typography variant="subtitle1">
                  <strong>{choice.prefix}:&nbsp;</strong>
                  {choice.description}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
        <TextField
          fullWidth
          variant="outlined"
          label="Notes"
          value={notes}
          onChange={handleNotesChange}
        />
      </CardContent>
    </Card>
  );
};

export default MultiResponseForm;
