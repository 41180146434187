import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MultiResponseForm, FreetextResponseForm, ScaleResponseForm, Spinner } from 'components';
import { useAtom } from 'jotai';
import { activeJuror, activeQuestion } from 'store';
import {
  useGetAllResponsesByQuestion,
  useUpdateResponse,
  useRemoveRespose,
} from 'hooks/queries/useResponsesQueries';
import { responsesByJuror } from 'state/reducers';
import { IScaleOptions } from 'state/interfaces';
import { debounce, isEqual, omit } from 'lodash';

const responseFormMap = {
  multi: MultiResponseForm,
  freetext: FreetextResponseForm,
  scale: ScaleResponseForm,
};

interface ResponseControllerProps {}

const ResponseController: React.FC<ResponseControllerProps> = () => {
  const { caseId } = useParams();
  const [currentJuror] = useAtom(activeJuror);
  const [currentQuestion] = useAtom(activeQuestion);
  const {
    data: responsesData,
    refetch: refetchResponses,
    isLoading,
  } = useGetAllResponsesByQuestion(caseId, currentQuestion?.id);

  const responsesQuestion = useMemo(
    () => (responsesData ? responsesByJuror(responsesData) : null),
    [responsesData],
  );
  const activeResponse = responsesQuestion?.[currentJuror.juror_id];

  const ResponseComponent = responseFormMap[currentQuestion?.type ?? 'multi'];

  const updateResponse = useUpdateResponse(caseId);
  const deleteResponse = useRemoveRespose(caseId);
  const handleUpdate = debounce(updates => {
    if (isEqual(updates, activeResponse?.response ?? currentQuestion.options)) return;

    // Should we fire off a response request?
    if (!currentQuestion) return;
    if (currentQuestion.type === 'multi') {
      const { notes, ...answers } = updates;
      if (
        !Object.entries(answers).length &&
        Boolean(notes) === Boolean((currentQuestion.options as IScaleOptions).notes)
      ) {
        return;
      }
    }

    let isText = false;
    if (currentQuestion.type === 'freetext') {
      isText = true;
      if (updates.text) {
        updates.text = updates.text?.replaceAll("'", '`');
      }
    } else if (activeResponse?.response?.notes !== updates.notes) {
      isText = true;
      if (updates.notes) {
        updates.notes = updates.notes?.replaceAll("'", '`');
      }
    }

    if (currentQuestion.type === 'freetext' && updates.text === '') {
      deleteResponse
        .mutateAsync({
          questionId: currentQuestion.id,
          responseId: activeResponse?.response_id,
        })
        .then(() => refetchResponses());
    } else {
      updateResponse
        .mutateAsync({
          juror_id: currentJuror.juror_id,
          question_id: currentQuestion.id,
          response: updates,
          isText,
        })
        .then(() => refetchResponses());
    }
  }, 50);

  if (isLoading) return <Spinner />;

  const key = `${currentJuror?.juror_id}${currentQuestion?.id}${
    currentQuestion?.type !== 'freetext'
      ? JSON.stringify(omit(activeResponse?.response, ['notes']) ?? {})
      : ''
  }`;

  return (
    <>
      <ResponseComponent
        key={key}
        question={currentQuestion}
        initialResponse={activeResponse?.response}
        onUpdate={handleUpdate}
      />
    </>
  );
};

export default ResponseController;
