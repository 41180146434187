import React, { useMemo } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Accordion as MuiExpansionPanel,
  AccordionDetails as MuiExpansionPanelDetails,
  AccordionSummary as MuiExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import { ResponseDetail, JurorDetail, StrikesList } from 'components';
import { IJuror, IQuestion, IQuestionResponseWithJuror } from 'state/interfaces';
import { partition } from 'lodash';

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  heading: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    position: 'sticky',
    top: -16,
    zIndex: 1,
    background: theme.palette.background.default,
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(-0.25),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  grow: {
    flexGrow: 1,
  },
  bold: {
    fontWeight: 700,
  },
}));

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
    alignItems: 'center',
  },
  expanded: {},
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiExpansionPanelDetails);

interface ResponsesByJurorProps {
  jurors: IJuror[];
  questions?: IQuestion[];
  responses?: unknown;
  strikes?: unknown;
  focusedJurorId?: string;
}

const ResponsesByJuror: React.FC<ResponsesByJurorProps> = ({
  jurors = [],
  responses,
  questions,
  strikes,
  focusedJurorId,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(() =>
    jurors.reduce((acc, juror, i) => {
      acc[juror.juror_id] = false;
      return acc;
    }, {}),
  );

  React.useEffect(() => {
    if (!focusedJurorId) return;

    const jurorSummaryElement: HTMLElement = document.querySelector(
      `[data-juror-id="${focusedJurorId}"]`,
    );
    if (!jurorSummaryElement) return;

    jurorSummaryElement.parentElement.scroll({
      top: jurorSummaryElement.offsetTop - 48,
      behavior: 'smooth',
    });

    setExpanded((expanded) => ({ ...expanded, [focusedJurorId]: true }));
  }, [focusedJurorId]);

  const createChangeHandler = (jurorId) => (e, newPanel) => {
    setExpanded({ ...expanded, [jurorId]: newPanel });
  };

  const [strikesForCause, noStrikes]: IJuror[][] = useMemo(
    () => partition(jurors, (juror) => Boolean(strikes?.[juror.juror_id])),
    [jurors, strikes],
  );

  return (
    <>
      <Typography className={classes.heading} variant="h6" gutterBottom>
        Strikes for Cause
      </Typography>
      {strikesForCause.map((juror, i) => {
        const jurorResponses = responses?.[juror?.juror_id] ?? [];
        return (
          <ExpansionPanel
            key={juror.juror_id}
            data-juror-id={juror.juror_id}
            square
            expanded={expanded[juror.juror_id]}
            onChange={createChangeHandler(juror.juror_id)}
          >
            <ExpansionPanelSummary
              aria-controls={`${juror.juror_id}-controls`}
              id={`${juror.juror_id}-header`}
            >
              <Typography className={classes.marginLeft}>
                <strong>{`#${juror.juror_number}: `}</strong>
                {`${juror.last_name}${juror.first_name ? ', ' + juror.first_name : ''}`}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <JurorDetail compact juror={juror} />
              <StrikesList jurorId={juror.juror_id} strikes={strikes} questions={questions} />
              {jurorResponses.map((response: IQuestionResponseWithJuror) => {
                return (
                  <ResponseDetail
                    variant="question"
                    key={response.response_id}
                    askingParty={response.asking_party}
                    question={response.question}
                    type={response.question_type}
                    options={response.question_options}
                    response={response.response}
                    responseId={response.response_id}
                  />
                );
              })}
              {!jurorResponses.length && (
                <Typography variant="body2" color="textSecondary">
                  No responses recorded for this juror.
                </Typography>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
      <Typography className={classes.heading} variant="h6" gutterBottom>
        Others
      </Typography>
      {noStrikes.map((juror, i) => {
        const jurorResponses = responses?.[juror?.juror_id] ?? [];
        return (
          <ExpansionPanel
            key={juror.juror_id}
            data-juror-id={juror.juror_id}
            square
            expanded={expanded[juror.juror_id]}
            onChange={createChangeHandler(juror.juror_id)}
          >
            <ExpansionPanelSummary
              aria-controls={`${juror.juror_id}-controls`}
              id={`${juror.juror_id}-header`}
            >
              <Typography className={classes.marginLeft}>
                <strong>{`#${juror.juror_number}: `}</strong>
                {`${juror.last_name}${juror.first_name ? ', ' + juror.first_name : ''}`}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <JurorDetail compact juror={juror} />
              {jurorResponses.map((response: IQuestionResponseWithJuror) => {
                return (
                  <ResponseDetail
                    variant="question"
                    key={response.response_id}
                    askingParty={response.asking_party}
                    question={response.question}
                    type={response.question_type}
                    options={response.question_options}
                    response={response.response}
                    responseId={response.response_id}
                  />
                );
              })}
              {!jurorResponses.length && (
                <Typography variant="body2" color="textSecondary">
                  No responses recorded for this juror.
                </Typography>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </>
  );
};

export default ResponsesByJuror;
