// @ts-nocheck

import React from 'react';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';

interface JurorPageListItemProps {
  firstName?: string;
  lastName?: string;
  jurorNumber?: number;
  selected?: boolean;
  onClick?: any;
}

const JurorPageListItem: React.FC<JurorPageListItemProps> = React.forwardRef(
  ({ selected = false, onClick = () => {}, firstName, lastName, jurorNumber, dragIcon = false, ...props }, ref) => {
    const name = React.useMemo(() => {
      let computedName = '';
      if (!firstName && !lastName) {
        computedName = '(unnamed juror)';
      } else {
        computedName += firstName ? `${firstName} ` : '';
        computedName += lastName ?? '';
      }
      return computedName;
    }, [firstName, lastName]);

    return (
      <div ref={ref}>
        <ListItem {...props} dense divider button={dragIcon ? null : true} selected={selected} onClick={onClick}>
          {dragIcon && (
            <ListItemIcon>
              <DragIndicator />
            </ListItemIcon>
          )}
          <ListItemText>{`#${jurorNumber}: ${name}`}</ListItemText>
        </ListItem>
      </div>
    );
  },
);

export default JurorPageListItem;
